import React, { useEffect, useState, useRef, useContext } from "react";
import { getMethod, patchMethod } from "API/APIResponse";
import { fetchExplore, updateExploreData } from "API/apiList";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import DeleteBreathwork from "./DeleteGuidedJob";
import {
  Box,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  List,
  Text,
  Input,
  FormControl,
  Select,
  FormLabel,
  Spinner,
  Flex,
  Switch
} from "@chakra-ui/react";

import { ViewIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Context } from "context/context";

interface GuidedJournalItem {
  suggestions: string | number | string[];
  question: string;
  hint: string;
}
interface TableData {
  id: string;
  name: string;
  dimension: string;
  desiredEndState: string;
  duration: number;
  isListening: boolean;
  isPrompt: boolean;
  promptText: string;
  journalQuestion: GuidedJournalItem[];
  // Add more data properties as needed
}

function ListBreathwork(props: {}) {
  const {search,initialRender}=useContext(Context);
  const navigate = useNavigate();  
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const quizArray = [
    "Bliss",
    "Wisdom",
    "Mental & emotional",
    "Energetic",
    "Physical",
  ];
  const stateArrayValues = ["Grow", "Focus", "Relax", "Sleep"];

  const [itemsPerPage, setItemPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPage] = useState<number>(0);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [isView, setIsView] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [itemData, setItemData] = useState<TableData | null>(null);
  const [dimension, setDimension] = useState<string>(quizArray[0]);
  const [name, setName] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [promptText, setPromptText] = useState<string>('');
  const [desiredEndState, setDesiredEndState] = useState<string>(stateArrayValues[0]);
  const [optionArr, setOptionArr] = useState<string[]>(stateArrayValues);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [itemDataID, setItemDataId] = useState<string>(null);
  const [guidedJournalData, setGuidedJournalData] = useState<GuidedJournalItem[]>([]);
  const [isPrompt, setPrompt] = useState<boolean>(true);

  const fetchData = async (page = 1) => {
    const token = localStorage.getItem("token");
    try {

      let url = `${fetchExplore}?limit=${itemsPerPage}&page=${page}&exploreType=Guided Journal`;
      url += search ? `&search=${search}` : ``;

      const result: any = await getMethod(
        url,
        {},
        token
      );
      if (result) {
        const { data } = result;
        if (data) {
          const { results, totalPages, page, limit } = data;
          setCurrentItems(results);
          setTotalPage(totalPages);
          setCurrentPage(page);
          setItemPerPage(limit);
          //    setCurrentItems(result)
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: "Guided Journal Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateGuidedJob = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem("token");
      // const formData = new FormData();
      // formData.append("dimension", dimension);
      // formData.append("duration", duration.toString());
      // formData.append("desiredEndState", desiredEndState);
      // formData.append("name", name);
      // formData.append("promptText", promptText);
      const data = {
        dimension, duration, desiredEndState, name,isPrompt, promptText, journalQuestion: guidedJournalData
      }
      const result: any = await patchMethod(
        updateExploreData + `/${itemData?.id}`,
        data,
        token
      );
      if (result) {
        setLoading(false)
        fetchData(currentPage);
        setIsEdit(false);
        setModalOpen(false);
        setItemData(null);
        toast({
          title: "Guided Journal Updated Successfully",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      setLoading(false)
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: "Guided Journals List failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const handleEdit = (item: TableData) => {
    setItemData(item);
    setDimension(item.dimension);
    setDuration(item.duration);
    setPromptText(item.promptText)
    setDesiredEndState(item.desiredEndState);
    setName(item.name);
    setGuidedJournalData(item.journalQuestion);
    setIsView(true);
    setDeleteModal(false)
    setModalOpen(true);
    setIsEdit(true);
  };
  const handleDemention = (e: any) => {
    const dimentionValue = e.target.value;
    setDimension(dimentionValue);
    setOptionArr(stateArrayValues);

  }


  const handleBreathwork = (item: TableData) => {
    setIsEdit(false);
    setIsView(true);
    setDeleteModal(false)
    setModalOpen(true);
    setItemData(item);
    setGuidedJournalData(item.journalQuestion);
  };

  const closeModal = () => {
    setItemDataId(null)
    setDeleteModal(false)
  }

  const deleteBreathworkMethod = (id: string) => {
    setItemDataId(id)
    setIsView(false);
    setDeleteModal(true)
  }

  const updateData = () => {
    fetchData(currentPage);
    setItemDataId(null)
    setDeleteModal(false)
    setIsView(false)
  }

  useEffect(() => {
    if(initialRender){
      fetchData();
    }else{
      const timeout = setTimeout(() => {
        fetchData();
      }, 500);
  
      return ()=>{
        clearTimeout(timeout)
      }
    }
  }, [props,search]);

  return (
    <>{
      loading && (<Flex
        align="center"
        justify="center"
        height="100vh"
        direction="column"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="rgba(255, 255, 255, 0.8)"
      >
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </Flex>)
    }
      {!loading && (<Box>
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
              <Th>Dimension</Th>
              <Th>Desired End State</Th>
              <Th>Duration</Th>
              <Th>Prompt-Text</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => (
              <Tr key={item.id}>
                <Td>{item.id}</Td>
                <Td>{item.name}</Td>
                <Td>{item.dimension}</Td>
                <Td>{item.desiredEndState}</Td>
                <Td>{item.duration}</Td>
                <Td>{item.promptText}</Td>
                <Td>
                  {" "}
                  <div className="buttonRow">
                    <Button
                      colorScheme="blue"
                      onClick={() => handleBreathwork(item)}
                    >
                      <ViewIcon />
                    </Button>
                    <Button
                      className="editButton"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => deleteBreathworkMethod(item.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box display="flex" justifyContent="center" mt={4}>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Box
              key={index}
              onClick={() => handlePageChange(index + 1)}
              cursor="pointer"
              fontWeight={index + 1 === currentPage ? "bold" : "normal"}
              mx={2}
            >
              <Button color="blue">{index + 1}</Button>
            </Box>
          ))}
        </Box>
        {deleteModal && !isView && (<DeleteBreathwork updateData={updateData} itemDataID={itemDataID} closeModal={closeModal} isModal={deleteModal} />)}
        {isView && (
          <Modal isOpen={isModalOpen} onClose={() => { setModalOpen(false); setIsView(false) }}>
            <ModalOverlay />
            <ModalContent>
              {isEdit ? (
                <ModalHeader color="#F1B452">Update Guided Journal</ModalHeader>
              ) : (
                <ModalHeader>Guided Journal Details</ModalHeader>
              )}
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <List spacing={3}>
                    {itemData && (
                      <>
                        <FormControl>
                          <FormLabel color="blue.500">Id :</FormLabel>{" "}
                          {itemData?.id}
                        </FormControl>
                        <FormControl>
                          <FormLabel color="blue.500" htmlFor="name">
                            Name :
                          </FormLabel>{" "}
                          {isEdit ? (
                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                          ) : (
                            itemData?.name
                          )}
                        </FormControl>
                        <FormControl>
                          <FormLabel color="blue.500" htmlFor="dimension">
                            Dimension :
                          </FormLabel>{" "}
                          {isEdit ? (
                            <Select
                              id="dimension"
                              value={dimension}
                              onChange={(e) => handleDemention(e)}
                              required
                            >
                              {quizArray && quizArray.map((e) =>
                                <option key={e} value={e}>{e}</option>
                              )}
                            </Select>
                          ) : (
                            itemData?.dimension
                          )}
                        </FormControl>
                        <FormControl>
                          <FormLabel color="blue.500" htmlFor="desiredEndState">
                            Desired End State :
                          </FormLabel>{" "}
                          {isEdit ? (
                            <Select
                              id="desiredEndState"

                              value={desiredEndState}
                              onChange={(e) => setDesiredEndState(e.target.value)}
                              required
                            >
                              {optionArr && optionArr.map((e) => (
                                <option key={e} value={e}>
                                  {e}
                                </option>
                              ))}
                            </Select>
                          ) : (
                            itemData?.desiredEndState
                          )}
                        </FormControl>
                        <FormControl>
                          <FormLabel color="blue.500" htmlFor="duration">
                            {" "}
                            Duration: :{" "}
                          </FormLabel>{" "}
                          {isEdit ? (
                            <Input
                              type="text"
                              value={duration}
                              onChange={(e) =>
                                setDuration(
                                  e.target.value ? parseInt(e.target.value) : 0
                                )
                              }
                            />
                          ) : (
                            itemData?.duration
                          )}
                        </FormControl>
                        <FormControl>
                          <Text color="blue.500">Prompt Text: </Text>
                          {isEdit ? (
                            <Textarea
                              value={promptText}
                              onChange={(e) =>
                                setPromptText(e.target.value)
                              }
                            />
                          ) : itemData.promptText}
                        </FormControl>
                        {guidedJournalData.map((item1, index1) =>{
                          return <> <FormControl>
                          <FormLabel color="blue.500" htmlFor="dimension">
                            Question {index1 + 1}:
                          </FormLabel>{" "}
                          {isEdit ? (
                            <Input type="text" value={item1.question} onChange={(e) =>{const updateValue = [...guidedJournalData];
                              updateValue[index1]['question'] = e.target.value;
                              setGuidedJournalData(updateValue)}}></Input>
                          ) : (
                            item1?.question
                          )}
                        </FormControl>
                        <FormControl>
                          <FormLabel color="blue.500" htmlFor="dimension">
                            Hint {index1 + 1}:
                          </FormLabel>{" "}
                          {isEdit ? (
                            <Input type="text" value={item1.hint} onChange={(e) =>{const updateValue = [...guidedJournalData];
                              if (e.target.value == '') {
                                updateValue[index1]['hint'] = null;

                              } else {

                              updateValue[index1]['hint'] = e.target.value;
                              }
                              setGuidedJournalData(updateValue)}}></Input>
                          ) : (
                            item1?.hint
                          )}
                        </FormControl>

                        <FormControl>
                          <FormLabel color="blue.500" htmlFor="dimension">
                            Suggestions {index1 + 1}:
                          </FormLabel>{" "}
                          {isEdit ? (
                            <Input type="text" value={item1.suggestions} onChange={(e) =>{const updateValue = [...guidedJournalData];
                              updateValue[index1]['suggestions'] = e.target.value;
                              setGuidedJournalData(updateValue)}}></Input>
                          ) : (
                            item1?.suggestions
                          )}
                        </FormControl>
                        </>
                        })}
                        
                      </>
                    )}
                  </List>
                </Box>
              </ModalBody>
              <ModalFooter>
                {isEdit ? (
                  <>
                    <Button
                      color="#F1B452"
                      mr={3}
                      onClick={() => updateGuidedJob()}
                    >
                      Submit
                    </Button>
                    <Button
                      colorScheme="gray"
                      mr={3}
                      onClick={() => { setModalOpen(false); setIsView(false) }}
                    >
                      Close
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="ghost"
                    mr={3}
                    onClick={() => { setModalOpen(false); setIsView(false) }}
                  >
                    Close
                  </Button>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        <br />
      </Box>)}</>
  );
}
export default ListBreathwork;
