import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import {
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  useToast,
  Button,
  Box,
  Text
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { postMethod } from 'API/APIResponse';
import logo from 'assets/img/logo.png';
import {
  createAffirmation,
} from 'API/apiList';
import { Navigate, useNavigate } from 'react-router-dom';

function AddAffirmations({
  handleChange,
}: {
  handleChange: any;
}) {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const navigate = useNavigate();
  const toast = useToast();
  const [file, setFile] = useState(null);
  const handleChangeFile = (file: any) => {
    setFile(file);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('date', new Date().toUTCString());
  
        const result = await postMethod(
          createAffirmation,
          formData,
          token
        );
  
        if (result) {
          handleChange(result);
          toast({
            title: 'Affirmations Created Successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
  
        } else {
          toast({
            title: 'Affirmations Create Failed',
            description: 'Affirmations cannot be empty',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        Navigate({ to:'/auth'})
      }
      
    } catch (error: any) {
      const axiosError: AxiosError = error;
      const messageData: any = axiosError?.response?.data
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Affirmations Create Failed',
        description: messageData?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack direction="row" mt={4} mb={4} pt={4} pb={4}>
      <Stack>

      </Stack>
      <Stack>
        <FormControl p={8} borderColor="blue" borderWidth={1} borderRadius={8} boxShadow="lg">
          <Stack mt={8} direction="row" spacing={4}>
            <Avatar size="xl" src={logo} mb={4} pos="relative" />
            <Text display={"flex"} alignItems={"center"}><strong>Add Affirmation</strong></Text>
          </Stack>

          <Box>
            <FileUploader classes={"drop_area drop_zone"} handleChange={handleChangeFile} name="file" types={fileTypes} />
            {file && (
              <div>
                Selected File: {file.name}
              </div>
            )}
          </Box>
          <br/>
          <Button onClick={handleSave}>Save</Button>
          <FormHelperText>Inner Workout</FormHelperText>
        </FormControl>
      </Stack>
      <Stack>

      </Stack>
    </Stack>
  );
}

export default AddAffirmations;