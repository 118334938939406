import React, { useState } from "react";
import Card from "components/card";
import ListAudioAffirmation from "./components/ListAudioAffirmation";
import AddAudioAffirmation from "./components/AddAudioAffirmation";
import { Button, Select ,Grid} from "@chakra-ui/react";



const Tables = () => {
  const [addAudioAffirmation, setAddAudioAffirmation] = useState(false);
  const handleChange = () => {
    setAddAudioAffirmation(!addAudioAffirmation);
  };
  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
      <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
           {addAudioAffirmation ? "Add AudioAffirmation" : "AudioAffirmation List" } 
          </div>

          {addAudioAffirmation === true ? <></> : 
          <Button colorScheme="blue" onClick={handleChange}>
            Add AudioAffirmation
          </Button>
         }
        </header>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addAudioAffirmation ? (<AddAudioAffirmation  handleChange={handleChange} />) : (  <ListAudioAffirmation  />) }
        </div>
      </Card>
    </div>
  );
};

export default Tables;
