import React, { useEffect, useState } from 'react';
import logo from 'assets/img/logo.png';
import {
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Button,
  Textarea,
  Input,
} from '@chakra-ui/react';
import { postMethod } from 'API/APIResponse';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { updateNotification } from 'API/apiList';


const EditNotifications: React.FC<{handleChange: any; data: any; setEdit:any ,setCurrentItems:any}> = (props) => {
const toast = useToast();
const [notification, setNotification] = useState<string>('');
const [key, setKey] = useState<string>('');
const { data, handleChange ,setEdit,setCurrentItems } = props;

let initialNotification: string | undefined;
let initialKey: string | undefined;

useEffect(() => {
  if (data.length >= 2) {
    initialNotification = data[1];
    initialKey = data[0];
    setNotification(initialNotification);
    setKey(initialKey);
  }
}, [data]);

const navigate = useNavigate();
  


  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
    const res = await postMethod(updateNotification,{key:key,value:notification},token)
    if (res && typeof res === 'object' && 'data' in res) {
      console.log("Updated Data",res.data);
      setCurrentItems([res.data]);
      toast({
        title: 'Notification Updated Successfully',
        description: '',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setEdit(false);
    }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Notification update Failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack  spacing={4} m={4} p={4}>
          <FormControl p={8} borderColor="blue" borderWidth={1} borderRadius={8} boxShadow="lg">
            <Stack mt={8} direction="row" spacing={4}>
              <Avatar size="xl" src={logo} mb={4} pos="relative" />
              <Heading fontSize="2xl" fontFamily="body">
                Notification
              </Heading>
            </Stack>
            <Textarea placeholder='Notification Description' value={notification} onChange={(event) => setNotification(event.target.value)} mb={2} />
            <Button colorScheme="blue" onClick={handleSave} isDisabled={notification === ''}>
              Save Notification
            </Button>
            <FormHelperText>Inner Workout</FormHelperText>
          </FormControl>
      </Stack>
    </>
  );

};

export default EditNotifications;
