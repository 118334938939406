import MiniCalendar from "components/calendar/MiniCalendar";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";

import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import { getMethod } from "API/APIResponse";
import { DashboardAPIUrl } from "API/apiList";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [userJourneyTotal, setUserJourneyTotal] = useState(0);
  const [userJourneyTotalDone, setJourneyTotalDone] = useState(0);
  const [userReflectionDone, setReflectionDone] = useState(0);
  const [userReflectionTotal, setReflectionTotal] = useState(0);
  const [userTotal, setuserTotal] = useState(0);
  const [customerSupport, setCustomerSupport] = useState(0);
  const navigate = useNavigate();
  const dashboardAPI = async () => {
    try {
      const token = localStorage.getItem('token');
      const result: any = await getMethod(DashboardAPIUrl, {}, token);
      if (result.data) {
        const {data} = result;
        if (data) {
          setJourneyTotalDone(data.userJourneyTotalDone);
          setUserJourneyTotal(data.userJourneyTotal);
          setReflectionDone(data.userReflectionDone);
          setReflectionTotal(data.userReflectionTotal);
          setuserTotal(data.userTotal);
          setCustomerSupport(data.customerSupport);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      
    }
  };
  useEffect(() =>{
    dashboardAPI();
  },[]);
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-5 gap-5 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"All Done Reflections"}
          subtitle={String(userReflectionDone)}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"All Reflections Started"}
          subtitle={String(userReflectionTotal)}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"All Done Journey Day"}
          subtitle={String(userJourneyTotalDone)}
        />
           <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"All Journey Day Started"}
          subtitle={String(userJourneyTotal)}
        />
        <Widget
        icon={<IoDocuments className="h-6 w-6" />}
        title={"Total User"}
        subtitle={String(userTotal)}
      />
         <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Total Customer Support"}
          subtitle={String(customerSupport)}
        />

      </div>
      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <MiniCalendar />
          <div className="grid grid-cols-1 rounded-[20px]">

          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
