import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { postMethod } from "../../API/APIResponse";
import { ResetPasswordAPI } from "API/apiList";
import { AxiosError } from "axios";
import InputField from "components/fields/InputField";
// Define TypeScript interfaces for the expected data structure
interface User {
    role: string;
    id: string;
    // Add other properties if needed
}

interface Tokens {
    access: {
        token: string;
        // Add other properties if needed
    };
}

interface ApiResponse {
    status?:number,
    data?: {
        user: User;
        tokens?: Tokens;
        // Add other properties if needed
    };
}

// Component for user sign-in
export default function ResetPassword() {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of the 'token' parameter
    const token = urlParams.get('token');
    // Chakra UI toast hook for displaying messages
    const toast = useToast();
    // State for password input
    const [password, setPassword] = useState<string>('');
    const [rePassword, setRePassword] = useState<string>('');
    const [reSetPassword, setReSetPassword] = useState<boolean>(false);
    const [helperText1, setText1] = useState<string>('');
    const [helperText2, setText2] = useState<string>('');
    // Function to handle user sign-in
    const handleSignIn = async () => {
        try {
            if (password === rePassword) {
                // Send a POST request to the login API
                const response: ApiResponse = await postMethod(`${ResetPasswordAPI}/?token=${token}`, { password }, "");

                // Check if the response contains user data
                if (response.status === 200) {

                    // Display an error message if the user doesn't have admin access
                    setReSetPassword(true);

                }
            } else {
                // Display an error message if the user doesn't have admin access
                toast({
                    title: "Password dosn't match",
                    description: 'Please match password',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }

        } catch (error: any) {
            // Handle API errors
            handleApiError(error);
        }
    };

    // Function to handle API errors and display error messages
    const handleApiError = (error: AxiosError) => {
        // if (error.response) {
        //     // Log details of the response error
        //     console.error('Request failed with status:', error.response.status);
        //     console.error('Error data:', error.response.data);
        // } else if (error.request) {
        //     // Log an error if no response is received from the server
        //     console.error('No response received from the server');
        // } else {
        //     // Log an error if there's an issue setting up the request
        //     console.error('Error setting up the request:', error.message);
        // }

        // Display an error message using toast
        toast({
            title: 'Sign-in failed',
            description: error?.response?.statusText || 'An error occurred',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    };

    // Event handler for password input change
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if(e.target.value.length > 8 ){
            setText1('Your password must be at least 8 characters long.')
             }else if(e.target.value.length === 8){
                setText1('')
             }
    };
    // Event handler for password input change
    const handleRePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRePassword(e.target.value);
        if(e.target.value.length > 8 ){
            setText2('Your password must be at least 8 characters long.')
             }else if(e.target.value.length === 8){
                setText2('')
             }
            
    };
    // CSS classes for the sign-in button
    const signInButtonClasses = "linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200";

    // Render the sign-in form
    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            {  reSetPassword && (
                      <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                      Thank you! Your password has been reset.
                  </h4> 
                    )
                }
              { !reSetPassword && (<><h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Reset Password
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your new password
                </p>

                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    id="password"
                    type="password"
                    onChange={handlePasswordChange}
                    value={password}
                    
                />
                 {helperText1 && <div className="helper-text"  style={{ color: 'red' }}>{helperText1}</div>}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    id="repassword"
                    type="password"
                    onChange={handleRePasswordChange}
                    value={rePassword}
                />
                  {helperText2 && <div className="helper-text" style={{ color: 'red' }} >{helperText2}</div>}
                {/* Sign-in button */}
                <button
                    onClick={handleSignIn}
                    className={signInButtonClasses}
                >
                    Reset Password
                </button></>)}
            </div>
        </div>
    );
}
