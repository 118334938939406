import React, { useEffect, useState } from 'react';
import logo from 'assets/img/logo.png';
import {
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Button,
  Spinner,
  Flex
} from '@chakra-ui/react';

import { getMethod, postMethod } from 'API/APIResponse';
import { createQuizJourney, fetchJourneyAll } from 'API/apiList';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { Select, useChakraSelectProps } from 'chakra-react-select';
import { Energetic, Physical, blissArray, mentalAndEnergeticArray, noQquizArray2, noQuizArray, noQuizArray3, noQuizArray4, noQuizArray5, noQuizArray6, wisdomArray, yesArray } from './DummyData';
import SelectField from './SelectField';


function AddQuizJourney(props: { tableData: any; handleChange: any }) {
  const toast = useToast();
  const [optionOne, setOptionOne] = useState<any>([]);
  const [optionTwo, setOptionTwo] = useState<any>([]);
  const [optionThree, setOptionThree] = useState<any>([]);
  const [optionOneOption, setOptionOneOption] = useState<any>([
    { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'Not yet' }
  ]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [journeyOptions, setJourneyOptions] = useState<any>([]);

  const selectProps = useChakraSelectProps({
    // isMulti: true,
    isSearchable: true,
    value: selectedOptions,
    options: journeyOptions,
    onChange: setSelectedOptions,
  });

  const fetchJourneys = async () => {
    try {
      const token = localStorage.getItem('token');
      const result: any = await getMethod(fetchJourneyAll, {}, token);
      if (result) {
        const { data } = result;
        if (data.length) {
          const options = [];
          for (let journey of data) {
            options.push({ label: journey.journeyName, value: journey.id });
          }
          console.log(options);
          setJourneyOptions(options);
        }
      }
    } catch (error) {

    }
  };
  console.log(optionOne);

  useEffect(() => {
    fetchJourneys();
  }, []);
  const handleSave = async (e: any) => {
    try {
      setLoading(true)
      const data = {
        journey: [selectedOptions],
        optionOne: optionOne.value, optionTwo: optionTwo.value, optionThree: optionThree.value
      };
      const token = localStorage.getItem('token');
      if (data) {
        const result = await postMethod(createQuizJourney, data, token);
        if (result) {
          setLoading(false)
          toast({
            title: 'Quiz Journey Created Successfully',
            description: '',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          props.handleChange(result);
        }
      }
    } catch (error: any) {
      setLoading(false)
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Quiz Journey Create Failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (<>
    {loading && (<Flex
      align="center"
      justify="center"
      height="100vh"
      direction="column"
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(255, 255, 255, 0.8)"
    >
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    </Flex>)}
    {!loading && (<>
      <Stack spacing={4} m={4} p={4}>
        <>
          <FormControl p={8} borderColor="blue" borderWidth={1} borderRadius={8} boxShadow="lg">
            <Stack mt={8} direction="row" spacing={4}>
              <Avatar size="xl" src={logo} mb={4} pos="relative" />
              <Heading fontSize="2xl" fontFamily="body">
                Quiz Journey
              </Heading>
            </Stack>
            <SelectField option={optionOne} optionOption={optionOneOption} setOption={setOptionOne} />
            <br />
            {/* {optionOne} */}
            {
              (optionOne && optionOne.label === 'Yes') && <>
                <SelectField option={optionTwo} optionOption={yesArray} setOption={setOptionTwo} />
              </>}
            {(optionOne && optionOne.label === 'No') && <>
              <SelectField option={optionTwo} optionOption={noQuizArray} setOption={setOptionTwo} />
            </>
            }
            <br />
            {
              (optionTwo && optionTwo.label === 'Physical' && optionOne.label === 'Yes') &&
              <SelectField option={optionThree} optionOption={Physical} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'Energetic' && optionOne.label === 'Yes') &&
              <SelectField option={optionThree} optionOption={Energetic} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'Mental & emotional' && optionOne.label === 'Yes') &&
              <SelectField option={optionThree} optionOption={mentalAndEnergeticArray} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'Wisdom' && optionOne.label === 'Yes') &&
              <SelectField option={optionThree} optionOption={wisdomArray} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'Bliss' && optionOne.label === 'Yes') &&
              <SelectField option={optionThree} optionOption={blissArray} setOption={setOptionThree} />
            }
            {/* For No */}
            {
              (optionTwo && optionTwo.label === 'I feel disconnected in my life.' && optionOne.label === 'No') &&
              <SelectField option={optionThree} optionOption={noQuizArray6} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'I’m not supporting my highest good through my actions.' && optionOne.label === 'No') &&
              <SelectField option={optionThree} optionOption={noQuizArray5} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'My brain feels like it’s working against me, not with me.' && optionOne.label === 'No') &&
              <SelectField option={optionThree} optionOption={noQuizArray4} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'My energy is all over the place.' && optionOne.label === 'No') &&
              <SelectField option={optionThree} optionOption={noQuizArray3} setOption={setOptionThree} />
            }
            {
              (optionTwo && optionTwo.label === 'My relationship with my body isn’t what it could be.' && optionOne.label === 'No') &&
              <SelectField option={optionThree} optionOption={noQquizArray2} setOption={setOptionThree} />
            }
            <br />
            {optionThree && <Select
              {...selectProps}
            />}

            <br />
            <Button colorScheme="blue" onClick={handleSave} isDisabled={optionThree === ''}>
              Save Quiz Journey
            </Button>
            <FormHelperText>Inner Workout</FormHelperText>
          </FormControl>
        </>
      </Stack>

    </>)}
  </>
  );
}

export default AddQuizJourney;
