import React, { useState } from "react";
import Card from "components/card";
import ListMeditation from "./components/ListMeditation";
import AddMeditation from "./components/AddMeditation";
import { Button, Select ,Grid} from "@chakra-ui/react";



const Tables = () => {
  const [addMeditation, setAddMeditation] = useState(false);
  const handleChange = () => {
    setAddMeditation(!addMeditation);
  };
  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
      <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
           {addMeditation ? "Add Meditation" : "Meditation List" } 
          </div>

          {addMeditation === true ? <></> : 
          <Button colorScheme="blue" onClick={handleChange}>
            Add Meditation
          </Button>
         }
        </header>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addMeditation ? (<AddMeditation handleChange={handleChange} />) : (  <ListMeditation  />) }
        </div>
      </Card>
    </div>
  );
};

export default Tables;
