import React, { ChangeEvent, useRef, useState } from "react";
import {
  Textarea,
  FormLabel,
  Switch,
  Input,
  Button,
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Box,
  Flex,
  Text,
  Tooltip
} from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import { patchMethod } from "API/APIResponse";
import { updateJourneyDay } from "API/apiList";
import { AxiosError } from "axios";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

interface TextWithDuration {
  text: string;
  duration: string;
}

interface questionWithDuration {
  question: string;
}

function AddJourney(props: {
  tableData: any;
  handleChange: any;
  journeyId: string;
  data: any;
}) {
  const dayData = props.data;
  const textData: TextWithDuration[] = props.data?.promptTextAndInput;
  const audioDirectory = 'http://35.171.220.209:8000/v1/';

  const toast = useToast();
  const [isBreathWork, setBreathing] = useState<boolean>(Boolean(dayData.isBreathWork));
  const [isfocus, setFocus] = useState<boolean>(Boolean(dayData.focus));
  const [isRelax, setRelax] = useState<boolean>(Boolean(dayData.relax));
  const [isSleep, setSleep] = useState<boolean>(Boolean(dayData.sleep));
  const [isGrow, setGrow] = useState<boolean>(Boolean(dayData.grow));
  const [isListening, setListening] = useState<boolean>(Boolean(dayData.isListening));
  const [isPrompt, setPrompt] = useState<boolean>(Boolean(dayData.isPrompt));
  const [time, setTime] = useState<string>(dayData.time ? dayData.time : '');
  const [inputs, setInputs] = useState<questionWithDuration[]>(
    dayData.journeyQuestion ? dayData.journeyQuestion : [{ question: '' }]
  ); // Initialize with one input field
  const [title, setTitle] = useState<string>(dayData.title); // Initialize with one input field
  const [inputTextWithDurations, setInputTextWithDurations] = useState<
    TextWithDuration[]
  >(textData ? textData : [{ text: "", duration: "0" }]);
  const [preTextInput, setPreTextInput] = useState<string>(dayData.promptText ? dayData.promptText : '');
  const [conclusionInput, setConclusionInput] = useState<string>(
    dayData.conclusion ? dayData.conclusion : ''
  );
  const fileInputRef = useRef<HTMLInputElement | null>(dayData.audio);
  const [selectedFile, setSelectedFile] = useState<string | null>(
    dayData.audio
  );
  const [playFile, setPlayFile] = useState<string | null>(audioDirectory + dayData.audio);
  const [hint, setHints] = useState<string>(dayData.hint);
  const navigate = useNavigate();

  const handleAddInputTextWithDuration = () => {
    if (
      inputTextWithDurations.length &&
      inputTextWithDurations[inputTextWithDurations.length - 1].duration
    ) {
      setInputTextWithDurations([
        ...inputTextWithDurations,
        {
          text: "",
          duration: String(
            parseInt(
              inputTextWithDurations[inputTextWithDurations.length - 1].duration
            ) + 10
          ),
        },
      ]);
    } else {
      setInputTextWithDurations([
        ...inputTextWithDurations,
        { text: "", duration: "0" },
      ]);
    }
  };

  const handleInputChangeTextWithDuration = (
    index: number,
    key: "text" | "duration",
    value: string
  ) => {
    const updatedInputs = [...inputTextWithDurations];
    updatedInputs[index][key] = value;
    setInputTextWithDurations(updatedInputs);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      // Handle the selected file (e.g., upload, display, etc.)
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file.name);
        setPlayFile(URL.createObjectURL(file));
      } else {
        setSelectedFile(null);
      }
    }
  };
  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveInputTextWithDuration = (index: number) => {
    const updatedInputs = [...inputTextWithDurations];
    updatedInputs.splice(index, 1);
    setInputTextWithDurations(updatedInputs);
  };

  const handlePreText = (value: string) => {
    const newText = value;
    setPreTextInput(newText);
  };

  const handleConclusion = (value: string) => {
    const newText = value;
    setConclusionInput(newText);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      const questionsArray = inputs.map(input => input.question);
      formData.append("questions", JSON.stringify(questionsArray));
      formData.append("file", fileInputRef.current!.files[0] || dayData.audio);
      formData.append("isBreathWork", isBreathWork.toString());
      formData.append("title", title);
      formData.append(
        "isListening",
        isPrompt ? isListening.toString() : isPrompt.toString()
      );
      formData.append("time", time.toString());
      formData.append("isPrompt", isPrompt.toString());
      formData.append("focus", isfocus.toString());
      formData.append("relax", isRelax.toString());
      formData.append("grow", isGrow.toString());
      formData.append("sleep", isSleep.toString());
      formData.append("promptText", preTextInput);
      formData.append("conclusion", conclusionInput);
      formData.append(
        "promptTextAndInput",
        JSON.stringify(inputTextWithDurations)
      );
      formData.append("journeyId", props.journeyId);
      const token = localStorage.getItem("token");
      if (formData) {
        const result = await patchMethod(
          updateJourneyDay + `/${props.data?.id}`,
          formData,
          token
        );
        if (result) {
          toast({
            title: "Journey Days Updated Successfully",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          props.handleChange(result);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: "Journey Days Updated Failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <FormControl
        p={8}
        borderColor="blue"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
      >

        <Stack mt={8} direction="row" spacing={4}>
          <Avatar size="xl" src={logo} mb={4} pos="relative" />
        </Stack>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="title" mb="0">
            <Heading fontSize="xl" fontFamily="body">
              Title
            </Heading>
          </FormLabel>
          <Input
            type="text"
            required
            placeholder="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <br />
        <Box bg="orange.50" p={4}>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="time" mb="0">
              <Heading fontSize="xl" fontFamily="body">
                Time
              </Heading>
            </FormLabel>
            <Tooltip label="Time in Minutes" aria-label="A tooltip">
              <Input
                type="text"
                required
                placeholder="5"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </Tooltip>
          </FormControl>
        </Box>
        <br />
        <Box bg="blue.100" p={4}>
          <Stack direction={{ base: "column", md: "row" }} display="flex" spacing={8}>
            <Flex flexWrap="wrap">
              <Box flexBasis={{ base: '100%', md: '50%' }}>
                <Box>
                  <Input
                    type="file"
                    accept="audio/*"
                    required
                    display="none"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button leftIcon={<AddIcon />} onClick={handleFileSelect}>
                    Upload File
                  </Button>
                  {selectedFile && <Text display="flex" flexDirection={{ base: 'column', md: 'row' }}>Selected File: {selectedFile}</Text>}
                </Box>
              </Box>
            </Flex>
            {selectedFile && (
              <Box flexBasis={{ base: '50%', md: '50%' }}>
                <audio controls style={{ width: '80%' }}>
                  <source src={playFile} type="audio/mp3" />
                </audio>
              </Box>
            )}

          </Stack>
        </Box>
        <br />
        {isPrompt && (
          <Stack direction={{ base: 'column', xl: 'row' }} spacing={4}>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isBreathwork" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  isBreathwork
                </Heading>
              </FormLabel>
              <Switch
                id="isBreathwork"
                isChecked={isBreathWork}
                onChange={(e) => setBreathing(!isBreathWork)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="focus" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  focus
                </Heading>
              </FormLabel>
              <Switch
                id="focus"
                isChecked={isfocus}
                onChange={(e) => setFocus(!isfocus)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="relax" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  relax
                </Heading>
              </FormLabel>
              <Switch
                id="relax"
                isChecked={isRelax}
                onChange={(e) => setRelax(!isRelax)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="sleep" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  sleep
                </Heading>
              </FormLabel>
              <Switch
                id="sleep"
                isChecked={isSleep}
                onChange={(e) => setSleep(!isSleep)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="grow" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  grow
                </Heading>
              </FormLabel>
              <Switch
                id="grow"
                isChecked={isGrow}
                onChange={(e) => setGrow(!isGrow)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isPrompt" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  isPrompt
                </Heading>
              </FormLabel>
              <Switch
                id="isPrompt"
                isChecked={isPrompt}
                onChange={(e) => setPrompt(!isPrompt)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isListening" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  isListening
                </Heading>
              </FormLabel>
              <Switch
                id="isListening"
                isChecked={isListening}
                onChange={(e) => setListening(!isListening)}
              />
            </FormControl>
          </Stack>
        )}
        <br />
        {inputTextWithDurations.map((input, index) => (
          <Box key={index} bg="gray.100" p={4}>
            <Stack direction={"row"}>
              {" "}
              <FormControl>
                <FormLabel>Text</FormLabel>
                <Input
                  type="text"
                  value={input.text}
                  onChange={(e) =>
                    handleInputChangeTextWithDuration(
                      index,
                      "text",
                      e.target.value
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Duration</FormLabel>
                <Input
                  type="number"
                  value={input.duration}
                  onChange={(e) =>
                    handleInputChangeTextWithDuration(
                      index,
                      "duration",
                      e.target.value
                    )
                  }
                />
              </FormControl>
            </Stack>
            <br />
            <Stack>
              {inputTextWithDurations.length && (
                <Button
                  colorScheme="red"
                  onClick={() =>
                    handleRemoveInputTextWithDuration(
                      inputTextWithDurations.length - 1
                    )
                  }
                >
                  Remove
                </Button>
              )}
              <Button
                colorScheme="teal"
                onClick={handleAddInputTextWithDuration}
              >
                Add Input
              </Button>
            </Stack>
          </Box>
        ))}
        <br />

        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="promptText" mb="0">
            <Heading fontSize="m" fontFamily="body">
              PreText
            </Heading>
          </FormLabel>
          <Textarea
            value={preTextInput}
            onChange={(e) => handlePreText(e.target.value)}
          />
        </FormControl>
        <br />
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="conclusion" mb="0">
            <Heading fontSize="m" fontFamily="body">
              Conclusion
            </Heading>
          </FormLabel>
          <Textarea
            value={conclusionInput}
            onChange={(e) => handleConclusion(e.target.value)}
          />
        </FormControl>
        <FormHelperText>Inner Workout</FormHelperText>
      </FormControl>
      <Button onClick={handleSubmit} colorScheme="blue">
        Save
      </Button>
      <br />
    </>
  );
}

export default AddJourney;
