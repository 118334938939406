import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Image } from '@chakra-ui/react';

interface ImagePreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedImage: string;
}

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({ isOpen, onClose, selectedImage }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image src={selectedImage} alt="Full screen preview" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImagePreviewModal;
