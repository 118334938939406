import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ResetPassword from "layouts/auth/reset-password";
import ResetPin from "layouts/auth/reset-pin";
import { useState } from "react";
import { Context } from "context/context";

const App = () => {
  const [search, setSearch] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [data, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [indexOfLastItem, setindexOfLastItem] = useState(0);
  return (
    <Context.Provider
      value={{
        search,
        setSearch,
        initialRender, 
        setInitialRender
        // data,
        // setCurrentItems,
        // currentPage,
        // setCurrentPage,
        // totalPages,
        // setTotalPage,
        // itemsPerPage,
        // setItemPerPage,
        // indexOfLastItem,
        // setindexOfLastItem,
      }}
    >
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="reset-pin" element={<ResetPin />} />
        <Route path="/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/default" replace />} />
      </Routes>
    </Context.Provider>
  );
};

export default App;
