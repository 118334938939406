import axios, { AxiosResponse, AxiosError } from 'axios';

const httpRequest = async <T>(
  method: 'get' | 'post' | 'delete' | 'patch',
  apiUrl: string,
  data?: object,
  token?: string
): Promise<T> => {
  try {
    const headers: { Authorization?: string } = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response: AxiosResponse<T> = await axios({
      method,
      url: apiUrl,
      data,
      headers,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response) {
        console.error('Request failed with status:', axiosError.response.status);
      
        console.error('Error data:', axiosError.response.data);
      } else if (axiosError.request) {
        console.error('No response received from the server');
      } else {
        console.error('Error setting up the request:', axiosError.message);
      }
      throw axiosError;
    } else {
      // console.error('Non-Axios error:', error);
      throw error;
    }
  }
};

export const getMethod = async <T>(apiUrl: string, data:object, token:string): Promise<T> => {
  return httpRequest<T>('get', apiUrl, data, token);
};

export const deleteMethod = async <T>(apiUrl: string,data: object, token: string): Promise<T> => {
  return httpRequest<T>('delete', apiUrl, data,token);
};

export const postMethod = async <T>(apiUrl: string, postData: object, token: string): Promise<T> => {
  return httpRequest<T>('post', apiUrl, postData, token);
};

export const patchMethod = async <T>(apiUrl: string, postData: object, token: string): Promise<T> => {
  return httpRequest<T>('patch', apiUrl, postData, token);
};



