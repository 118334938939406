type RowObj = {
	id: number;
    name: string;
    role: string;
    email: string;
};

const tableDataComplex: RowObj[] = [
	{
		id: 87675675,
		name: "apple",
		role: 'user',
		email: 'user@123'
	},
	{
		id: 87675675,
		name: "apple",
		role: 'user',
		email: 'user@123'
	},
	
];
export default tableDataComplex;
