import { Box, Button, FormControl, FormLabel, Input, Text, VStack } from "@chakra-ui/react";
import Card from "components/card";
import { useEffect, useState } from "react";

const ProfileOverview = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState('');

  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem('user'));
    setFormData({
      name: user.name,
      email: user.email,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
  },[]);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Basic password validation
    if (formData.newPassword !== formData.confirmPassword) {
      setPasswordError('New password and confirm password must match.');
      return;
    }

    // Reset password error on successful submission
    setPasswordError('');
  };
  return (
    <div className="flex w-full flex-col gap-5">
      <Card>
      <Box p="4">
        <form onSubmit={handleSubmit} className="w-[75%] mx-auto py-8">
          <VStack spacing="4">
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Current Password</FormLabel>
              <Input
                type="password"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleChange}
                placeholder="Enter your current password"
              />
            </FormControl>

            <FormControl>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                placeholder="Enter your new password"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm your new password"
              />
            </FormControl>

            {passwordError && (
              <Text color="red.500" fontSize="sm">
                {passwordError}
              </Text>
            )}

            <Button colorScheme="blue" type="submit" className="w-full">
              Submit
            </Button>
          </VStack>
        </form>
      </Box>
      </Card>
    
    </div>
  );
};

export default ProfileOverview;
