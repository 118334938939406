import React, { ChangeEvent, useRef, useState } from "react";
import {
  Textarea,
  FormLabel,
  Switch,
  Input,
  Button,
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Tooltip,
  Flex,
  Text,
  Box,
  Spinner,
  FormErrorMessage,
} from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import { postMethod } from "API/APIResponse";
import { createJourneyDay } from "API/apiList";
import { AxiosError } from "axios";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "@chakra-ui/icons";
interface TextWithDuration {
  text: string;
  duration: string;
}

function AddJourney(props: {
  tableData: any;
  handleChange: any;
  journeyId: string;
}) {
  const toast = useToast();
  const [isBreathWork, setBreathing] = useState<boolean>(true);
  const [isfocus, setFocus] = useState<boolean>(true);
  const [isRelax, setRelax] = useState<boolean>(true);
  const [isSleep, setSleep] = useState<boolean>(true);
  const [isGrow, setGrow] = useState<boolean>(true);
  const [isListening, setListening] = useState<boolean>(true);
  const [isMeditation, setMeditation] = useState<boolean>(true);
  const [isPrompt, setPrompt] = useState<boolean>(true);
  const [time, setTime] = useState<string>("5");
  // const [promptText, setPromptText] = useState<string | null>('');
  const [inputs, setInputs] = useState<string[]>([""]); // Initialize with one input field
  const [title, setTitle] = useState<string>(""); // Initialize with one input field
  const [inputTextWithDurations, setInputTextWithDurations] = useState<
    TextWithDuration[]
  >([{ text: "", duration: "0" }]);
  const [preTextInput, setPreTextInput] = useState<string>("");
  const [conclusionInput, setConclusionInput] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [playFile, setPlayFile] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [hint, setHints] = useState<string>("");
  const [showButton, setShowButton] = useState([0]);
  const [buttonIndex, setButtonIndex] = useState(0);

  const [isTitleInvalid, setIsTitleInvalid] = useState<boolean>(false);
  const [isFileInvalid, setIsFileInvalid] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleAddInputTextWithDuration = () => {
    let m = inputTextWithDurations.length - 1;
    setButtonIndex(m + 1);
    setShowButton([buttonIndex + 1]);
    console.log("showButton", showButton, "buttonIndex", buttonIndex);

    if (
      inputTextWithDurations.length &&
      inputTextWithDurations[inputTextWithDurations.length - 1].duration
    ) {
      setInputTextWithDurations([
        ...inputTextWithDurations,
        {
          text: "",
          duration: String(
            parseInt(
              inputTextWithDurations[inputTextWithDurations.length - 1].duration
            ) + 10
          ),
        },
      ]);
    } else {
      setInputTextWithDurations([
        ...inputTextWithDurations,
        { text: "", duration: "0" },
      ]);
    }
  };

  const handleInputChangeTextWithDuration = (
    index: number,
    key: "text" | "duration",
    value: string
  ) => {
    const updatedInputs = [...inputTextWithDurations];
    updatedInputs[index][key] = value;
    setInputTextWithDurations(updatedInputs);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file.name);
        setPlayFile(URL.createObjectURL(file));
        setIsFileInvalid(false);
      } else {
        setSelectedFile(null);
      }
    }
  };
  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveInputTextWithDuration = (index: number) => {
    const updatedInputs = [...inputTextWithDurations];
    updatedInputs.splice(index, 1);
    setInputTextWithDurations(updatedInputs);
  };

  const handlePreText = (value: string) => {
    const newText = value;
    setPreTextInput(newText);
  };

  const handleConclusion = (value: string) => {
    const newText = value;
    setConclusionInput(newText);
  };

  const handleSubmit = async () => {
    try {
      if (title === "") {
        setIsTitleInvalid(true);
        return;
      }

      if (!fileInputRef.current!.files.length) {
        setIsFileInvalid(true);
        return;
      }

      setLoading(true);
      const formData = new FormData();
      formData.append("file", fileInputRef.current!.files[0]);
      formData.append("isBreathWork", isBreathWork.toString());
      formData.append("title", title);
      formData.append(
        "isListening",
        isPrompt ? isListening.toString() : isPrompt.toString()
      );
      formData.append("time", time.toString());
      formData.append("focus", isfocus.toString());
      formData.append("relax", isRelax.toString());
      formData.append("grow", isGrow.toString());
      formData.append("sleep", isSleep.toString());
      formData.append("isPrompt", isPrompt.toString());
      formData.append("isMeditation", isMeditation.toString());
      formData.append("questions", JSON.stringify(inputs));
      formData.append("promptText", preTextInput);
      formData.append("conclusion", conclusionInput);
      formData.append(
        "promptTextAndInput",
        JSON.stringify(inputTextWithDurations)
      );
      formData.append("journeyId", props.journeyId);
      const token = localStorage.getItem("token");
      if (formData) {
        const result = await postMethod(createJourneyDay, formData, token);
        if (result) {
          setLoading(false);
          toast({
            title: "Journey Days Created Successfully",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          props.handleChange(result);
        }
      }
    } catch (error: any) {
      setLoading(false);
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Journey Days Create Failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {loading && (
        <Flex
          align="center"
          justify="center"
          height="100vh"
          direction="column"
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}
      {!loading && (
        <>
          <br />
          <FormControl
            p={8}
            borderColor="blue"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
          >
            <Stack mt={8} direction="row" spacing={4}>
              <Avatar size="xl" src={logo} mb={4} pos="relative" />
            </Stack>
            <FormControl
              display="flex"
              alignItems="center"
              isInvalid={isTitleInvalid}
            >
              <FormLabel htmlFor="title" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  Title
                </Heading>
              </FormLabel>
              <Input
                type="text"
                required
                placeholder="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setIsTitleInvalid(false);
                }}
              />
              {isTitleInvalid ? (
                <FormErrorMessage className="ml-4">
                  Title is required
                </FormErrorMessage>
              ) : (
                ""
              )}
            </FormControl>

            <br />
            <Box bg="orange.50" p={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="time" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    Time
                  </Heading>
                </FormLabel>
                <Tooltip label="Time in Minutes" aria-label="A tooltip">
                  <Input
                    type="text"
                    required
                    placeholder="5"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </Tooltip>
              </FormControl>
            </Box>
            <br />
            <FormControl isInvalid={isFileInvalid}>
              <Box bg="blue.100" p={4}>
                <Stack
                  direction={{ base: "row", md: "column" }}
                  display="flex"
                  spacing={8}
                >
                  <Flex flexWrap="wrap">
                    <Box flexBasis={{ base: "100%", md: "50%" }}>
                      <Box>
                        <Input
                          type="file"
                          accept="audio/*"
                          required
                          display="none"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                        <Button
                          leftIcon={<AddIcon />}
                          onClick={handleFileSelect}
                        >
                          Upload File
                        </Button>
                        {isFileInvalid ? (
                          <FormErrorMessage>
                            Audio File is required
                          </FormErrorMessage>
                        ) : (
                          ""
                        )}
                        {selectedFile && (
                          <Text
                            display="flex"
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            Selected File: {selectedFile}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Flex>
                  {selectedFile && (
                    <Box flexBasis={{ base: "50%", md: "50%" }}>
                      <audio controls style={{ width: "80%" }}>
                        <source src={playFile} type="audio/mp3" />
                      </audio>
                    </Box>
                  )}
                </Stack>
              </Box>
            </FormControl>
            <br />
            <Stack direction={{ base: "column", xl: "row" }} spacing={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="isBreathwork" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    isBreathwork
                  </Heading>
                </FormLabel>
                <Switch
                  id="isBreathwork"
                  isChecked={isBreathWork}
                  onChange={(e) => setBreathing(!isBreathWork)}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="focus" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    focus
                  </Heading>
                </FormLabel>
                <Switch
                  id="focus"
                  isChecked={isfocus}
                  onChange={(e) => setFocus(!isfocus)}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="relax" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    relax
                  </Heading>
                </FormLabel>
                <Switch
                  id="relax"
                  isChecked={isRelax}
                  onChange={(e) => setRelax(!isRelax)}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="sleep" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    sleep
                  </Heading>
                </FormLabel>
                <Switch
                  id="sleep"
                  isChecked={isSleep}
                  onChange={(e) => setSleep(!isSleep)}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="grow" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    grow
                  </Heading>
                </FormLabel>
                <Switch
                  id="grow"
                  isChecked={isGrow}
                  onChange={(e) => setGrow(!isGrow)}
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="isMeditation" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    isMeditation
                  </Heading>
                </FormLabel>
                <Switch
                  id="isMeditation"
                  isChecked={isMeditation}
                  onChange={(e) => setMeditation(!isMeditation)}
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="isPrompt" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    isPrompt
                  </Heading>
                </FormLabel>
                <Switch
                  id="isPrompt"
                  isChecked={isPrompt}
                  onChange={(e) => setPrompt(!isPrompt)}
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="isListening" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    isListening
                  </Heading>
                </FormLabel>
                <Switch
                  id="isListening"
                  isChecked={isListening}
                  onChange={(e) => setListening(!isListening)}
                />
              </FormControl>
            </Stack>
            <br />
            {inputTextWithDurations.map((input, index) => (
              <Box key={index} bg="gray.100" p={4}>
                <Stack direction={"row"}>
                  {" "}
                  <FormControl>
                    <FormLabel>Text</FormLabel>
                    <Input
                      type="text"
                      value={input.text}
                      onChange={(e) =>
                        handleInputChangeTextWithDuration(
                          index,
                          "text",
                          e.target.value
                        )
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Duration</FormLabel>
                    <Input
                      type="number"
                      value={input.duration}
                      onChange={(e) =>
                        handleInputChangeTextWithDuration(
                          index,
                          "duration",
                          e.target.value
                        )
                      }
                    />
                  </FormControl>
                  <Flex alignItems="end">
                    <Button
                      colorScheme="red"
                      onClick={() =>
                        handleRemoveInputTextWithDuration(
                          inputTextWithDurations.length - 1
                        )
                      }
                    >
                      <DeleteIcon />
                    </Button>
                  </Flex>
                </Stack>
                <br />
                <Stack>
                  {showButton.includes(buttonIndex) &&
                    index == inputTextWithDurations.length - 1 && (
                      <Button
                        colorScheme="teal"
                        onClick={handleAddInputTextWithDuration}
                      >
                        Add New Text
                      </Button>
                    )}
                </Stack>
              </Box>
            ))}
            <br />
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="promptText" mb="0">
                <Heading fontSize="m" fontFamily="body">
                  PreText
                </Heading>
              </FormLabel>
              <Textarea
                value={preTextInput}
                onChange={(e) => handlePreText(e.target.value)}
              />
            </FormControl>
            <br />
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="conclusion" mb="0">
                <Heading fontSize="m" fontFamily="body">
                  Conclusion
                </Heading>
              </FormLabel>
              <Textarea
                value={conclusionInput}
                onChange={(e) => handleConclusion(e.target.value)}
              />
            </FormControl>
            <FormHelperText>Inner Workout</FormHelperText>
          </FormControl>
          <Button onClick={handleSubmit} colorScheme="blue">
            Save
          </Button>
        </>
      )}
    </>
  );
}

export default AddJourney;
