import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ConfirmationModalProps {
    isOpen: boolean;
    sendall: any;
    setsendall: any;
    onClose: () => void;
    handleSent:()=>void;
    handleSelectAll:()=>void;
    children?: ReactNode;
  }
  
  function ConfirmationModal({ isOpen,sendall,setsendall, onClose,handleSent,handleSelectAll }: ConfirmationModalProps) {
  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={()=>{
        onClose();
        setsendall(false);
      }}>
        <ModalOverlay />
        <ModalContent rounded={"30px"}>
          <ModalHeader>Confirm Send</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Text fontWeight='bold'>
              Are you sure want to send {sendall && "all user"}
            </Text>
          </ModalBody>
          <ModalFooter className='mx-auto gap-5'>
            <Button colorScheme='green' onClick={()=>{
                onClose();
                setsendall(false);
            }}>
              No
            </Button>
            <Button onClick={()=>{
                handleSent();
                onClose();
                setsendall(false);
            }} colorScheme='blue'>Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
