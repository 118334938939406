import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { postMethod } from "../../API/APIResponse";
import { apiUrl, ResetPinAPI } from "API/apiList";
import axios, { AxiosError } from "axios";
import InputField from "components/fields/InputField";

// Define TypeScript interfaces for the expected data structure
interface User {
  role: string;
  id: string;
  // Add other properties if needed
}

interface Tokens {
  access: {
    token: string;
    // Add other properties if needed
  };
}

interface ApiResponse {
  data?: {
    user: User;
    tokens?: Tokens;
    // Add other properties if needed
  };
}

// Component for user sign-in
export default function ResetPin() {
  // Get the URL parameters
  const urlParams = new URLSearchParams(window.location.search);

  // Get the value of the 'token' parameter
  const token = urlParams.get("token");

  // Chakra UI toast hook for displaying messages
  const toast = useToast();
  // State for password input
  const [pin, setPin] = useState<string>("");
  const [rePin, setRePin] = useState<string>("");
  const [reSetPin, setReSetPin] = useState<boolean>(false);
  const [userPinExpire, setUserPinExpire] = useState<boolean>(false);
  const [helperText1, setText1] = useState<string>("");
  const [helperText2, setText2] = useState<string>("");
  console.log("token", token);
  console.log("userPinExpire", userPinExpire);
  const getUserDetails = async () => {
    // Send a GET request to the user details API
    try {
        const response: ApiResponse = await axios.get(
          `${apiUrl}auth/isResetPin/?token=${token}`
        );
        if (response) {
          setUserPinExpire(false);
        }
    } catch (error:any) {
        console.log("error",error?.response?.data)
        if(error?.response?.data?.status===500 || error?.response?.data?.status===401 || error?.response?.data?.message=="jwt expired"){
            setUserPinExpire(true);
        }
    }

  };
  //useeffect
  useEffect(() => {
    getUserDetails();
  }, []);

  // Function to handle user sign-in
  const handleSignIn = async () => {
    try {
      if (pin && pin.length === 4 && pin === rePin) {
        // Send a POST request to the login API
        const response: ApiResponse = await postMethod(
          `${ResetPinAPI}/?token=${token}`,
          { pin },
          token
        );
        //  console.log("resss==>>",response);

        // Check if the response contains user data
        if (response) {
          // Display an error message if the user doesn't have admin access
          setReSetPin(true);
        }
      } else {
        toast({
          title: "Pin not match",
          description: "Pin not matched",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      // Handle API errors
      handleApiError(error);
    }
  };

  // Function to handle API errors and display error messages
  const handleApiError = (error: AxiosError) => {
    // if (error.response) {
    //     // Log details of the response error
    //     console.error('Request failed with status:', error.response.status);
    //     console.error('Error data:', error.response.data);
    // } else if (error.request) {
    //     // Log an error if no response is received from the server
    //     console.error('No response received from the server');
    // } else {
    //     // Log an error if there's an issue setting up the request
    //     console.error('Error setting up the request:', error.message);
    // }

    // Display an error message using toast
    toast({
      title: "Sign-in failed",
      description: error?.response?.statusText || "An error occurred",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  // Event handler for password input change
  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value);
    if (e.target.value.length > 4) {
      setText1("Your Pin must be at least 4 characters long.");
    } else if (e.target.value.length === 4) {
      setText1("");
    }
  };

  // Event handler for password input change
  const handlRePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRePin(e.target.value);
    if (e.target.value.length > 4) {
      setText2("Your pin must be at least 4 characters long.");
    } else if (e.target.value.length === 4) {
      setText2("");
    }
  };

  // CSS classes for the sign-in button
  const signInButtonClasses =
    "linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200";

  // Render the sign-in form
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {reSetPin && !userPinExpire && (
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Thank you! Your pin has been reset.
          </h4>
        )}
        {!reSetPin && !userPinExpire && (
          <>
            <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
              Reset Pin
            </h4>

            <InputField
              variant="auth"
              extra="mb-3"
              label="New PIN"
              placeholder="Min. 4 number and max. 4 number"
              id="pin"
              type="password"
              onChange={handlePinChange}
              value={pin}
            />
            {helperText1 && (
              <div className="helper-text" style={{ color: "red" }}>
                {helperText1}
              </div>
            )}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Verify PIN"
              placeholder="Min. 4 number and max. 4 number"
              id="re-pin"
              type="password"
              onChange={handlRePinChange}
              value={rePin}
            />
            {helperText2 && (
              <div className="helper-text" style={{ color: "red" }}>
                {helperText2}
              </div>
            )}

            {/* Sign-in button */}
            <button onClick={handleSignIn} className={signInButtonClasses}>
              Reset Pin
            </button>
          </>
        )}
        {userPinExpire && (
            <>
          <h4 className="mb-2.5 text-4xl font-bold text-red-700 dark:text-white">
            Your reset pin link has been expired 
          </h4>
          <p>
            Please request a new reset pin link. Once you receive the new link, follow the instructions to reset your pin.
            If you haven't received the new link, please check your spam folder or contact our support team for assistance.
            <br />
            <br />
            If you have any further questions, feel free to reach out to our support team at
            <a
              href="#"
              className="text-brand-500 hover:text-brand-600 dark:text-brand-400 dark:hover:text-brand-300"
            >{"  "}
            takecare@app.innerworkout.co
            </a>
            .
            <br />
            

          </p>
            </>
        )}
      </div>
    </div>
  );
}
