import React, { useState } from "react";
import logo from "assets/img/logo.png";
import {
  FormControl,
  Stack,
  Select,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Button,
  Textarea,
  Input,
  Text,
  Flex,
} from "@chakra-ui/react";

import InputField from "components/fields/InputField";
import { getMethod, postMethod } from "API/APIResponse";
import {
  createReflection,
  createReflectionQuestion,
  fetchReflection,
} from "API/apiList";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

function AddReflections(props: {
  tableData: any;
  handleChange: any;
  reflectionName: string;
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<string>("");
  const [hint, setHint] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Array<String>>([""]);

  const handleSave = async (e: any) => {
    const updatedSuggestions = suggestions.filter((data) => data !== "");
    try {
      const token = localStorage.getItem("token");
      if (props.reflectionName && question) {
        const reflection: any = await getMethod(
          fetchReflection + `/?reflectionName=${props.reflectionName}`,
          {},
          token
        );
        if (reflection) {
          const { data } = reflection;
          if (data) {
            if (data) {
              const result = await postMethod(
                createReflectionQuestion,
                { reflectionId: data.id, question, hint,suggestions:updatedSuggestions },
                token
              );
              if (result) {
                props.handleChange(false);
                toast({
                  title: "Reflections Created Successfully",
                  description: "",
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                props.handleChange(result);
              }
            }
          }
        }
      }
      if (!question) {
        toast({
          title: "Reflections Create Failed",
          description: "Question not be empty",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Reflections Create Failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateSuggestions = (index: number, data: string) => {
    const updatedSuggestions = [...suggestions];
    updatedSuggestions[index] = data;
    setSuggestions(updatedSuggestions);
  };

  return (
    <>
      <Stack direction="row">
        <Stack>
          <FormControl
            p={8}
            borderColor="blue"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
            width="500px"
          >
            <Stack mt={8} direction="row" spacing={4}>
              <Avatar size="xl" src={logo} mb={4} pos="relative" />
              <Text display={"flex"} alignItems="center">
                <strong>Add Question</strong>
              </Text>
            </Stack>
            <Input
              placeholder="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            ></Input>
            <br />
            <br />
            <Input
              placeholder="hint"
              value={hint}
              onChange={(e) => setHint(e.target.value)}
            ></Input>
            <br />
            <br />
            {suggestions.map((data, index) => {
              return (
                <>
                  <Input
                    placeholder="prompt"
                    value={data.toString()}
                    onChange={(e) => updateSuggestions(index, e.target.value)}
                  ></Input>
                  <br />
                  <br />
                </>
              );
            })}
            <Button
              colorScheme="teal"
              style={{ width: "100%" }}
              onClick={() => {
                setSuggestions([...suggestions, ""]);
              }}
            >
              Add More Prompts
            </Button>
            <br />
            <br />
            <Button onClick={handleSave} style={{ width: "100%" }}>
              Save
            </Button>
            <FormHelperText>Inner Workout</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>
      <br />
    </>
  );
}

export default AddReflections;
