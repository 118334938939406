
const tableDataCheck = [
  ['Horizon UI PRO', true],
  2458,
  '17.5%',
  '12 Jan 2021',

];
export const quizArray = [
  "Bliss",
  "Wisdom",
  "Mental & emotional",
  "Energetic",
  "Physical"
];

export const quizArray2 = ["Yes", "Not yet"];

export const quizArray3 = ["Not yet", "Yes"];

export const yesArray = [
  { label: 'Bliss', value: 'Bliss' },
  { label: 'Wisdom', value: 'Wisdom' },
  { label: 'Mental & emotional', value: 'Mental & emotional' },
  { label: 'Energetic', value: 'Energetic' },
  { label: 'Physical', value: 'Physical' }
];

export const quizArray4 = [
  { label: "IDon’tAppreciateMyPhysicalBodyAsItIs", value: "I don’t appreciate my physical body as it is" },
  { label: "IDon’tKnowWhatWorksAndDoesn’tWorkForMyBody.", value: "I don’t know what works and doesn’t work for my body." },
  { label: "IAmNotPresentInMyPhysicalBody.", value: "I am not present in my physical body." }
];
export const Physical = [
  { label: "Embodiment", value: "Embodiment" },
  { label: "Body Acceptance", value: "Body acceptance" },
  { label: "Body Awareness", value: "Body awareness" }
];
export const Energetic = [
  { label: "Energetic Zones", value: "Energetic Zones" },
  { label: "Breath Quality", value: "Breath Quality" }
];

export const blissArray = [
  { label: "Connection To Self", value: "Connection to Self" },
  { label: "Connection To Community", value: "Connection to Community" },
  { label: "Connection To Something Bigger", value: "Connection to Something Bigger" }
];

export const wisdomArray = [
  { label: "Self Trust", value: "Self Trust" },
  { label: "Focus", value: "Focus" },
  { label: "Aligned Action", value: "Aligned Action" }
];

export const mentalAndEnergeticArray = [
  { label: "Input", value: "Input" },
  { label: "Sleep", value: "Sleep" },
  { label: "Emotions", value: "Emotions" }
];

export const quizArray7 = [
  { label: "I feel disconnected in my life.", value: "I feel disconnected in my life." },
  { label: "I’m not supporting my highest good through my actions.", value: "I’m not supporting my highest good through my actions." },
  { label: "My brain feels like it’s working against me, not with me.", value: "My brain feels like it’s working against me, not with me." },
  { label: "My energy is all over the place.", value: "My energy is all over the place." },
  { label: "My relationship with my body isn’t what it could be.", value: "My relationship with my body isn’t what it could be." }
];

export const noQuizArray = [
  { label: "I feel disconnected in my life.", value: "I feel disconnected in my life." },
  { label: "I’m not supporting my highest good through my actions.", value: "I’m not supporting my highest good through my actions." },
  { label: "My brain feels like it’s working against me, not with me.", value: "My brain feels like it’s working against me, not with me." },
  { label: "My energy is all over the place.", value: "My energy is all over the place." },
  { label: "My relationship with my body isn’t what it could be.", value: "My relationship with my body isn’t what it could be." }
];

export const noQquizArray2 = [
  { label: "I don’t appreciate my physical body as it is", value: "I don’t appreciate my physical body as it is" },
  { label: "I don’t know what works and doesn’t work for my body", value: "I don’t know what works and doesn’t work for my body" },
  { label: "I am not present in my physical body", value: "I am not present in my physical body" }
];

export const noQuizArray3 = [
  { label: "My energy feels like it’s constantly being drained", value: "My energy feels like it’s constantly being drained" },
  { label: "My breath doesn’t support my well-being", value: "My breath doesn’t support my well-being" }
];

export const noQuizArray4 = [
  { label: "I don’t know how to healthfully engage with my emotions", value: "I don’t know how to healthfully engage with my emotions" },
  { label: "I struggle to prioritize sleep", value: "I struggle to prioritize sleep" },
  { label: "I can’t remember the last time I challenged myself", value: "I can’t remember the last time I challenged myself" }
];

export const noQuizArray5 = [
  { label: "There’s a disconnect between my goals and my actions.", value: "There’s a disconnect between my goals and my actions." },
  { label: "I rarely live in the present moment.", value: "I rarely live in the present moment." },
  { label: "I struggle to trust myself.", value: "I struggle to trust myself." }
];

export const noQuizArray6 = [
  { label: "I don’t feel connected to anything bigger than myself.", value: "I don’t feel connected to anything bigger than myself." },
  { label: "I lack connection to community.", value: "I lack connection to community." },
  { label: "I change myself to please others.", value: "I change myself to please others." }
];
export default tableDataCheck;
