/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import EditQuizJourney from "./EditQuizJourney";
// import Table from 'react-bootstrap/Table';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import { ViewIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { deleteMethod, getMethod } from "API/APIResponse";
import { deleteQuizJourney, fetchQuizJourney } from "API/apiList";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import tableDataDevelopment from "../variables/tableDataDevelopment";
import { Context } from "context/context";

interface TableData {
  id: string;
  optionOne: string;
  optionTwo: string;
  optionThree: string;
  // Add more data properties as needed
}

function ListQuizJourney(props: { tableData: any }) {
  // const { tableData } = props;
  const { search,initialRender } = useContext(Context);
  const navigate = useNavigate();
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [totalPages, setTotalPage] = useState(0);
  const [editJourney, setEditJourney] = useState(false);
  const [itemData, setItemData] = useState({});
  const handleChange = (item: any) => {
    setEditJourney(!editJourney);
    setItemData(item);
    fetchData(currentPage);
  };
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const viewQuizJourney = (id: any) => {
    navigate(`/quiz/${id}`);
  };
  const deleteQuizJourneyMethod = async (id: any) => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const result: any = await deleteMethod(
          deleteQuizJourney + `/${id}`,
          {},
          token
        );
        if (result) {
          await fetchData(currentPage);
          toast({
            title: "Quiz Journey Delete",
            description: `Quiz Journey id ${id} deleted successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Quiz Journey Delete failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        let url = `${fetchQuizJourney}?page=${page}&limit=${itemsPerPage}`;
        url += search ? `&search=${search}` : ``;
        const result: any = await getMethod(
          url,
          {},
          token
        );
        if (result) {
          const { data } = result;
          if (data) {
            const { results, totalPages, page, limit } = data;
            setTotalPage(totalPages);
            setCurrentPage(page);
            setItemPerPage(limit);
            setCurrentItems(results);
          }
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Quiz Journey List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if(initialRender){
      fetchData();
    }else{
      const timeout = setTimeout(() => {
        fetchData();
      }, 500);
  
      return ()=>{
        clearTimeout(timeout)
      }
    }
  }, [search]);

  return (
    <div>
      {editJourney ? (
        <EditQuizJourney
          tableData={tableDataDevelopment}
          handleChange={handleChange}
          data={itemData}
        />
      ) : (
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Option 1</Th>
                <Th>Option 2</Th>
                <Th>Option 3</Th>
                <Th>Actions</Th>
                {/* Add more table headers for additional data */}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.id}</Td>
                  <Td>{item.optionOne}</Td>
                  <Td>{item.optionTwo}</Td>
                  <Td>{item.optionThree}</Td>
                  <Td>
                    {" "}
                    <div className="buttonRow">
                      {/* <Button
                        colorScheme="blue"
                        onClick={() => viewQuizJourney(item.id)}
                      >
                        <ViewIcon />
                      </Button> */}
                      <Button
                        className="editButton"
                        onClick={() => handleChange(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => deleteQuizJourneyMethod(item.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box display="flex" justifyContent="center" mt={4} mb={4}>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Box
                key={index}
                onClick={() => handlePageChange(index + 1)}
                cursor="pointer"
                fontWeight={index + 1 === currentPage ? "bold" : "normal"}
                mx={2}
              >
                <Button color="blue">{index + 1}</Button>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
}
export default ListQuizJourney;
