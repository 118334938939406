import React, { useState } from "react";
import Card from "components/card";
import ListBreathwork from "./components/ListBreathwork";
import AddBreathwork from "./components/AddBreathwork";
import { Button, Select ,Grid} from "@chakra-ui/react";



const Tables = () => {
  const [addBreathwork, setAddBreathwork] = useState(false);
  const handleChange = () => {
    setAddBreathwork(!addBreathwork);
  };
  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
      <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
           {addBreathwork ? "Add Breathwork" : "Breathwork List" } 
          </div>

          {addBreathwork === true ? <></> : 
          <Button colorScheme="blue" onClick={handleChange}>
            Add Breathwork
          </Button>
         }
        </header>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addBreathwork ? (<AddBreathwork handleChange={handleChange} />) : (  <ListBreathwork  />) }
        </div>
      </Card>
    </div>
  );
};

export default Tables;
