import React, { useContext, useEffect, useState } from "react";
// import Table from 'react-bootstrap/Table';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import Card from "components/card";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { getMethod } from "API/APIResponse";
import { customerSupport } from "API/apiList";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import { Context } from "context/context";
import ImagePreviewModal from "./ImageModal/Imagemodal";
const apiUrl = process.env.REACT_APP_API_URL;

interface TableData {
  id: number;
  name: string;
  subject: string;
  email: string;
  files: string[];
  date: string;
  // Add more data properties as needed
}

function ListAffirmation() {
  const { search, initialRender } = useContext(Context);
  const toast = useToast();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [totalPages, setTotalPage] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    onOpen();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");

      let url = `${customerSupport}?page=${page}`;
      url += search ? `&search=${search}` : ``;

      const result: any = await getMethod(url, {}, token);
      if (result) {
        if (result.data) {
          const { data } = result;
          // console.log("customer support data :",data);
          if (data.results) {
            setCurrentItems(data.results);
            setTotalPage(data.totalPages);
          }
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "CustomerSupport List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    if (currentPage) {
      if (initialRender) {
        fetchData(currentPage);
      } else {
        const timeout = setTimeout(() => {
          fetchData(currentPage);
        }, 500);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Name</Th>
                <Th>Subject</Th>
                <Th>Message</Th>
                <Th>Action</Th>
                {/* Add more table headers for additional data */}
              </Tr>
            </Thead>
            <Tbody>
              {data.length > 1 &&
                data.map((item) => (
                  <Tr key={item.id}>
                    <Td>{item.email ? item.email : "null"}</Td>
                    <Td>{item.name}</Td>
                    <Td>{item.subject}</Td>
                    <Td>
                      {!item.files[0] ? (
                        "No Attachment Found"
                      ) : (
                        <img
                          src={apiUrl + item.files[0]}
                          width={120}
                          alt="No attachment found"
                          onClick={() =>
                            handleImageClick(apiUrl + item.files[0])
                          }
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Td>
                    <Td></Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
        <Box display="flex" justifyContent="center" mt={4} mb={4}>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Box
              key={index}
              onClick={() => handlePageChange(index + 1)}
              cursor="pointer"
              fontWeight={index + 1 === currentPage ? "bold" : "normal"}
              mx={2}
            >
              <Button color="blue">{index + 1}</Button>
            </Box>
          ))}
        </Box>
        <ImagePreviewModal
          isOpen={isOpen}
          onClose={onClose}
          selectedImage={selectedImage}
        />
      </Card>
    </div>
  );
}

export default ListAffirmation;
