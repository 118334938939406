import React, { useState } from "react";
import Card from "components/card";
import ListJourney from "./components/ListJourney";
import AddJourney from "./components/AddJourney";
import tableDataDevelopment from "./variables/tableDataDevelopment";
import { Button, Grid } from "@chakra-ui/react";

const Tables = () => {
  const [addJourney, setAddJourney] = useState(false);

  const handleChange = () => {
    setAddJourney(!addJourney);
  };

  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Quiz Journey List
          </div>

          {addJourney === true ? <></> :
            <Grid className='buttonRow' gap={2}>
              <Button colorScheme="blue" onClick={handleChange}>
                Add Journey
              </Button>
            </Grid>}
        </header>

        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addJourney ? (
            <AddJourney tableData={tableDataDevelopment} handleChange={handleChange} />
          ) : (
            <ListJourney tableData={tableDataDevelopment} />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Tables;
