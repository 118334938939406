import React, { useState } from 'react';
import logo from 'assets/img/logo.png';
import {
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Button,
  Textarea,
  Input,
} from '@chakra-ui/react';
import { patchMethod } from 'API/APIResponse';
import { updateJourney } from 'API/apiList';
import { AxiosError } from 'axios';

import { useNavigate } from 'react-router-dom';


const EditJourney: React.FC<{ tableData: any; handleChange: any; data: any }> = (props) => {
  const { data, handleChange } = props;
  const toast = useToast();

  const [id, setId] = useState<string>('');
  const [journeyName, setJourneyName] = useState<string>('');
  const [journeyDescription, setDescriptionJourney] = useState<string>('');
  const navigate = useNavigate();
  useState(() => {
    if (data) {
      setId(data.id);
      setJourneyName(data.journeyName);
      setDescriptionJourney(data.description);
    }
  })

  const handleSave = async () => {
    try {
      const result = await patchMethod(updateJourney + `/${id}`, {
        journeyName,
        description: journeyDescription,
      }, localStorage.getItem('token'));

      if (result) {
        setJourneyName('');
        setDescriptionJourney('');
        setId('');
        toast({
          title: 'Journey Updated Successfully',
          description: '',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        handleChange(result);
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Journey Create Failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack  spacing={4} m={4} p={4}>
          <FormControl p={8} borderColor="blue" borderWidth={1} borderRadius={8} boxShadow="lg">
            <Stack mt={8} direction="row" spacing={4}>
              <Avatar size="xl" src={logo} mb={4} pos="relative" />
              <Heading fontSize="2xl" fontFamily="body">
                Journey
              </Heading>
            </Stack>
            <Input type="text" id="journeyName" placeholder="Journey Name" value={journeyName} onChange={(e) => setJourneyName(e.target.value)} variant="journey" border="1px solid blue" mb={2} />
            <Textarea placeholder='Journey Description' value={journeyDescription} onChange={(event) => setDescriptionJourney(event.target.value)} mb={2} />
            <Button colorScheme="blue" onClick={handleSave} isDisabled={journeyName === ''}>
              Save Journey
            </Button>
            <FormHelperText>Inner Workout</FormHelperText>
          </FormControl>
      </Stack>
    </>
  );

};

export default EditJourney;
