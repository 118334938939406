import React, { useContext, useEffect, useState } from "react";
import {
  useToast,
  Box,
  Button,
  Td,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  List,
  ListItem,
  Text,
  Input,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { getMethod, patchMethod } from "API/APIResponse";
import { fetchAllUsers } from "API/apiList";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import DeleteUser from "./DeleteUser";
import { Context } from "context/context";

interface TableData {
  id: string;
  name: string;
  role: string;
  email: string;
  isAskedOnboardingQuestions: boolean;
  isEmailVerified: boolean;
  isMembershipTaken: boolean;
}

interface CheckTableProps {}

const CheckTable: React.FC<CheckTableProps> = () => {
  const toast = useToast();
  const {
    search,
    initialRender,
    // data,
    // setCurrentItems,
    // currentPage,
    // setCurrentPage,
    // totalPages,
    // setTotalPage,
  } = useContext(Context);
  const navigate = useNavigate();
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [userData, setUser] = useState<TableData | null>(null);
  const [name, setName] = useState("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [userDataID, setUserDataId] = useState<string>(null);

  const getUsersMethod = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      let url = `${fetchAllUsers}?page=${page}&limit=10`;
      url += search ? `&search=${search}` : ``;
      const result: any = await getMethod(url, {}, token);
      if (result) {
        const { data } = result;
        if (data) {
          const { results, totalPages } = data;
          setCurrentItems(results);
          setTotalPage(totalPages);
          setCurrentPage(page);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Users List failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleModal = (user: TableData) => {
    setUser(user);
    setEditable(false);
    setModalOpen(true);
  };

  const handleEdit = (user: TableData) => {
    setUser(user);
    setName(user.name);
    setEditable(true);
    setModalOpen(true);
  };

  const updateUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const result: any = await patchMethod(
        `${fetchAllUsers}/${userData.id}`,
        { name },
        token
      );
      if (result) {
        getUsersMethod(currentPage);
        setEditable(false);
        setModalOpen(false);
        setUser(null);
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Users List failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    getUsersMethod(newPage);
  };

  const updatePage = () => {
    getUsersMethod(currentPage);
  };

  const closeModal = () => {
    setUserDataId(null);
    setDeleteModal(false);
  };

  const deleteUserMethod = (id: string) => {
    setUserDataId(id);
    setDeleteModal(true);
  };

  useEffect(() => {
    if(initialRender){
      getUsersMethod();
    }else{
      const timeout = setTimeout(() => {
        getUsersMethod();
      }, 500);
  
      return ()=>{
        clearTimeout(timeout)
      }
    }
  }, [search]);

  return (
    <Card extra="w-full h-full sm:overflow-auto px-6">
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          User List
        </div>
      </header>
      <Box>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Table className="w-full">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.id}</Td>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.role}</Td>
                  <Td>
                    <div className="buttonRow">
                      <Button
                        colorScheme="blue"
                        onClick={() => handleModal(user)}
                      >
                        <ViewIcon />
                      </Button>
                      <Button
                        className="editButton"
                        onClick={() => handleEdit(user)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => deleteUserMethod(user.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Box>

      <Box display="flex" justifyContent="center" mt={4} mb={4}>
        {deleteModal && !isModalOpen && (
          <DeleteUser
            userId={userDataID}
            closeModal={closeModal}
            updatePage={updatePage}
            isModal={deleteModal}
          />
        )}
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader color="#F1B452">User Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <List spacing={3}>
                    {userData && (
                      <>
                        <ListItem>
                          <Text color="blue.500">Id :</Text> {userData?.id}
                        </ListItem>
                        <ListItem>
                          <Text color="blue.500">Name :</Text>{" "}
                          {isEditable ? (
                            <Input
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          ) : (
                            userData?.name
                          )}
                        </ListItem>
                        <ListItem>
                          <Text color="blue.500">Email :</Text>{" "}
                          {userData?.email}
                        </ListItem>
                        <ListItem>
                          <Text color="blue.500">Role :</Text> {userData?.role}
                        </ListItem>
                        <ListItem>
                          <Text color="blue.500"> isMembershipTaken : </Text>{" "}
                          {userData?.isMembershipTaken ? "true" : "false"}
                        </ListItem>
                        <ListItem>
                          <Text color="blue.500"> isEmailVerified : </Text>
                          {userData?.isEmailVerified ? "true" : "false"}
                        </ListItem>
                        <ListItem>
                          <Text color="blue.500">
                            isAskedOnboardingQuestions :{" "}
                          </Text>
                          {userData?.isAskedOnboardingQuestions
                            ? "true"
                            : "false"}
                        </ListItem>
                      </>
                    )}
                  </List>
                </Box>
              </ModalBody>
              <ModalFooter>
                {isEditable ? (
                  <div>
                    <Button color="#F1B452" mr={3} onClick={() => updateUser()}>
                      Submit
                    </Button>
                    <Button
                      colorScheme="gray"
                      mr={3}
                      onClick={() => setModalOpen(false)}
                    >
                      Close
                    </Button>
                  </div>
                ) : (
                  <Button
                    colorScheme="gray"
                    mr={3}
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </Button>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        {Array.from({ length: totalPages }).map((_, index) => (
          <Box
            key={index}
            onClick={() => handlePageChange(index + 1)}
            cursor="pointer"
            fontWeight={index + 1 === currentPage ? "bold" : "normal"}
            mx={2}
          >
            <Button color="blue">{index + 1}</Button>
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default CheckTable;
