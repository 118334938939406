import React, { useEffect, useState } from "react";
// import Table from 'react-bootstrap/Table';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";

import Card from "components/card";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { deleteMethod, getMethod, patchMethod } from "API/APIResponse";
import { createAffirmation, fetchAffirmation } from "API/apiList";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import { CheckIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { FileUploader } from "react-drag-drop-files";
const apiUrl = process.env.REACT_APP_API_URL;

interface TableData {
  id: number;
  file: string;
  date: string;
  // Add more data properties as needed
}

function ListAffirmation() {
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [totalPages, setTotalPage] = useState(0);
  const [editAffirmation, setEditAffirmation] = useState(null);
  const navigate = useNavigate();
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [file, setFile] = useState(null);
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      const result: any = await getMethod(
        fetchAffirmation + "/?page=" + page,
        {},
        token
      );
      if (result) {
        if (result.data) {
          const { data } = result;
          if (data.results) {
            setCurrentItems(data.results);
            setTotalPage(data.totalPages);
          }
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      console.log(error);
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Affirmation List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const editAffirmations = (data: any) => {
    setEditAffirmation(data);
  };
  const updateAffirmations = async (data: any) => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("date", editAffirmation.date);

        const result = await patchMethod(
          createAffirmation + "/" + editAffirmation.id,
          formData,
          token
        );
        if (result) {
          setEditAffirmation(null);
          toast({
            title: "Affirmations Updated Successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Reflection Delete failed",
        description: error?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const deleteAffirmation = async (id: any) => {
    try {
      const token = localStorage.getItem("token");
      const result: any = await deleteMethod(
        createAffirmation + `/${id}`,
        {},
        token
      );
      if (result) {
        await fetchData(currentPage);
        toast({
          title: "Affirmation Delete successfully",
          description: `Affirmation id ${id} deleted successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Affirmation Delete failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleChangeFile = (file: any) => {
    setFile(file);
  };
  useEffect(() => {
    if (currentPage) {
      fetchData(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>File</Th>
                <Th>Date</Th>
                <Th>Actions</Th>
                {/* Add more table headers for additional data */}
              </Tr>
            </Thead>
            <Tbody>
              {data.length > 0 &&
                data.map((item) => {
                  if (editAffirmation && editAffirmation.id === item.id) {
                    return (
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>
                        <Td>
                          <Box>
                            <FileUploader
                              classes={"drop_area drop_zone"}
                              handleChange={handleChangeFile}
                              name="file"
                              types={fileTypes}
                            />
                            {file && <div>Selected File: {file.name}</div>}
                          </Box>
                        </Td>
                        <Td>{new Date(item.date).toLocaleDateString()}</Td>

                        <Td>
                          {" "}
                          <div className="buttonRow">
                            <Button
                              className="editButton"
                              onClick={() => updateAffirmations(item)}
                            >
                              <CheckIcon />
                            </Button>
                          </div>
                        </Td>
                      </Tr>
                    );
                  } else {
                    return (
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>
                        <Td>
                          <img
                            src={apiUrl + item.file}
                            width={120}
                            alt="dummy"
                          />
                        </Td>
                        <Td>{new Date(item.date).toLocaleDateString()}</Td>

                        <Td>
                          {" "}
                          <div className="buttonRow">
                            <Button
                              className="editButton"
                              onClick={() => editAffirmations(item)}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() => deleteAffirmation(item.id)}
                            >
                              <DeleteIcon />
                            </Button>
                          </div>
                        </Td>
                      </Tr>
                    );
                  }
                })}
            </Tbody>
          </Table>
        </Box>

        <Box display="flex" justifyContent="center" mt={4} mb={4}>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Box
              key={index}
              onClick={() => handlePageChange(index + 1)}
              cursor="pointer"
              fontWeight={index + 1 === currentPage ? "bold" : "normal"}
              mx={2}
            >
              <Button color="blue">{index + 1}</Button>
            </Box>
          ))}
        </Box>
      </Card>
    </div>
  );
}

export default ListAffirmation;
