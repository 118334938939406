import React, { useEffect, useState } from "react";
import {
  useToast,
  Box,
  Button,
  Td,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { deleteMethod, getMethod } from "API/APIResponse";
import { fetchAllUsers } from "API/apiList";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Card from "components/card";
import { useNavigate } from "react-router-dom";

interface TableData {
  id: number;
  name: string;
  role: string;
  email: string;
}

interface CheckTableProps {
  tableData: TableData[];
}

function CheckTable(props: CheckTableProps) {
  const toast = useToast();
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [addUser, setAddUser] = useState(false);
  const navigate = useNavigate();

  const getUsersMethod = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      const result: any = await getMethod(
        `${fetchAllUsers}?page=${page}`,
        {},
        token
      );
      if (result) {
        const { data } = result;
        if (data) {
          const { results, totalPages } = data;
          setCurrentItems(results);
          setTotalPage(totalPages);
          setCurrentPage(page);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: "Users List failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const deleteUserAPI = async (userId: string): Promise<void> => {
    try {
      const token = localStorage.getItem('token');
      const result: any = await deleteMethod(`${fetchAllUsers}/${userId}`, {}, token);
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: "Users List failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    getUsersMethod(newPage);
  };
  const handleChange = () => { };
  useEffect(() => {
    getUsersMethod();
  }, []);

  return (
    <>
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            User List
          </div>

          {addUser === true ? <></> : <Button colorScheme="blue" onClick={handleChange}>
            Add User
          </Button>}
        </header>
        <Box>
          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <Table className="w-full">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.id}</Td>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.role}</Td>
                    <Td>
                      <div className="buttonRow">
                        <Button colorScheme="blue">
                          <ViewIcon />
                        </Button>
                        <Button className="editButton">
                          <EditIcon />
                        </Button>
                        <Button colorScheme="red">
                          <DeleteIcon />
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Box>

        <Box display="flex" justifyContent="center" mt={4} mb={4}>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Box
              key={index}
              onClick={() => handlePageChange(index + 1)}
              cursor="pointer"
              fontWeight={index + 1 === currentPage ? "bold" : "normal"}
              mx={2}
            >
              <Button color="blue">{index + 1}</Button>
            </Box>
          ))}
        </Box>
      </Card>
    </>
  );
}

export default CheckTable;