import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Checkbox,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import { Context } from "context/context";
import { getMethod, postMethod } from "API/APIResponse";
import { fetchAllUsers, sentCustomNotification } from "API/apiList";
import { FiSearch } from "react-icons/fi";
import ConfirmationModal from "./Confirmation/Modal";


function ListUser() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const [data, setCurrentItems] = useState<[]>([]);
  const [allUser, setallUser] = useState<[]>([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sendAll, setsendAll] = useState<any>(false);
  const [searchText, setsearchText] = useState<string>("");
  const [notificationText, setnotificationText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [totalPages, setTotalPage] = useState(0);
  const [indexOfLastItem, setindexOfLastItem] = useState(0);
  const {
    search,
    initialRender,
  } = useContext(Context);

  const selectedallUser = async (page = 1) => {
    try {
      const maxSafeInteger: bigint = BigInt(Number.MAX_SAFE_INTEGER);
      const token = localStorage.getItem("token");
      let url = `${fetchAllUsers}?page=${page}&limit=${maxSafeInteger}`;
      url += searchText ? `&search=${searchText}` : ``;
      const result: any = await getMethod(url, {}, token);
      if (result) {
        const { data } = result;
        if (data) {
          const { results } = data;
          const allIds = results.map((result:any) => result.id);
          setallUser(allIds);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
    }
  };

  const getUsersMethod = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      let url = `${fetchAllUsers}?page=${page}&limit=10`;
      url += searchText ? `&search=${searchText}` : ``;
      const result: any = await getMethod(url, {}, token);
      if (result) {
        const { data } = result;
        if (data) {
          const { results, totalPages,page, limit, totalResults } = data;
          setCurrentItems(results);
            setTotalPage(totalPages);
            setCurrentPage(page);
            setindexOfLastItem(totalResults);
            setItemPerPage(limit);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Users List failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (initialRender) {
      getUsersMethod();
      selectedallUser();
    } else {
      const timeout = setTimeout(() => {
        getUsersMethod();
      }, 500);

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [search,searchText]);

  const handleUserSelection = (userId:any) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };
  
  const handleSelectAll = () => {
    if (selectedUsers.length === data.length) {
      setSelectedUsers([]);
    } else {
      const allUserIds = data.map((user:any) => user.id);
      setSelectedUsers(allUserIds);
    }
  };
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    getUsersMethod(newPage);
  };
  
  const handleSent:any = async()=>{
    if(!sendAll && selectedUsers.length === 0){
      toast({
        title: "Please select the user",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    try {
      const token = localStorage.getItem("token");
      const response = await postMethod(sentCustomNotification,{
        userIds:sendAll ? allUser : selectedUsers,
        notificationText:notificationText
      },token)
      if(response){
        toast({
          title: "Notification sent successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setsendAll(false);
      }
    } catch (error:any) {
      console.log(error);
      toast({
        title: "No devices token found for the given users",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
  }
  }
  return (
    <div>
      <Box className="">
       <div className="flex gap-5 mb-5">
       <div
        className={`relative mt-[3px] flex h-[61px] 
           items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1  xl:gap-2`}
      >
        <div
          className={`h-full w-[400px] flex items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]`}
        >
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search"
            name="search"
            value={searchText}
            onChange={(e)=>setsearchText(e.target.value)}
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
      </div>
      <div
        className={`relative mt-[3px] flex h-[61px] w-[500px]
           items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1  xl:gap-2`}
      >
        <div
          className={`h-full w-[500px] flex items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white`}
        >
          <input
            type="text"
            placeholder="Write a message"
            name="search"
            value={notificationText}
            required
            onChange={(e)=>setnotificationText(e.target.value)}
            className="block h-full ml-2 w-[500px] rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
      </div>

      <div
      onClick={()=>{
        if(notificationText!==''){
          onOpen();
        }else{
          toast({
            title: "Please enter the notification message",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }}
        className={`relative mt-[3px] flex h-[61px] w-[200px]
           items-center cursor-pointer justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1  xl:gap-2`}
      >
        <Button
        borderRadius={"30px"}
          className={`h-full font-bold cursor-pointer w-[200px] flex justify-center items-center rounded-full dark:bg-brandLinear text-navy-700 dark:text-white dark:hover:bg-[blue] dark:hover:text-white`}
        >
         Send
        </Button>
      </div>
      <div
      onClick={()=>{
        if(notificationText!==''){
          setsendAll(true);
          onOpen();
        }else{
          toast({
            title: "Please enter the notification message",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }}
        className={`relative mt-[3px] flex h-[61px] w-[200px]
           items-center cursor-pointer justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1  xl:gap-2`}
      >
        <Button
        borderRadius={"30px"}
          className={`h-full font-bold cursor-pointer w-[200px] flex justify-center items-center rounded-full dark:bg-brandLinear text-navy-700 dark:text-white dark:hover:bg-[blue] dark:hover:text-white`}
        >
         Send to all user
        </Button>
      </div>
        {
          isOpen && (<ConfirmationModal setsendall={setsendAll} sendall={sendAll} handleSelectAll={handleSelectAll} isOpen={isOpen} handleSent={handleSent} onClose={onClose}/>)
        }
       </div>
        <Table>
          <Thead>
            <Tr>
              <Th>
              <Checkbox isChecked={selectedUsers.length === data.length} onChange={handleSelectAll}>
                  Select all
                </Checkbox>
              </Th>
              <Th>Email</Th>
              <Th>User</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((user: any) => {
              console.log(
                user
              );
              
              return (
                <Tr key={user.id}>
                  <Td>
                  <Checkbox
                    isChecked={selectedUsers.includes(user.id)}
                    onChange={() => handleUserSelection(user.id)}
                  />
                </Td>
                  <Td>{user.email}</Td>
                  <Td>{user.name}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Box display="flex" justifyContent="center" mt={4} mb={4}>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Box
                key={index}
                onClick={() => handlePageChange(index + 1)}
                cursor="pointer"
                fontWeight={index + 1 === currentPage ? "bold" : "normal"}
                mx={2}
              >
                <Button color="blue">{index + 1}</Button>
              </Box>
            ))}
          </Box>
      </Box>
    </div>
  );
}
export default ListUser;
