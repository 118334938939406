import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { postMethod } from "../../API/APIResponse";
import { LoginAPI } from "API/apiList";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";

// Define TypeScript interfaces for the expected data structure
interface User {
  role: string;
  id: string;
  // Add other properties if needed
}

interface Tokens {
  access: {
    token: string;
    // Add other properties if needed
  };
}

interface ApiResponse {
  data?: {
    user: User;
    tokens?: Tokens;
    // Add other properties if needed
  };
}

// Component for user sign-in
export default function SignIn() {
  // React Router hook for navigation
  const navigate = useNavigate();
  // Chakra UI toast hook for displaying messages
  const toast = useToast();
  // State for email input
  const [email, setEmail] = useState<string>('');
  // State for password input
  const [password, setPassword] = useState<string>('');

  // Function to handle user sign-in
  const handleSignIn = async () => {
    try {
      // Send a POST request to the login API
      const response: ApiResponse = await postMethod(LoginAPI, { email, password }, "");

      // Check if the response contains user data
      if (response.data && response.data.user) {
        const { user, tokens } = response.data;

        // Check if the user has admin role
        if (user.role === 'admin') {
          // Save user data to local storage
          localStorage.setItem("user", JSON.stringify(user));

          // Check if tokens are present in the response
          if (tokens && tokens.access && tokens.access.token) {
            // Save the access token to local storage
            localStorage.setItem("token", tokens.access.token);
            // Navigate to the dashboard
            navigate('/default');
          }
        } else {
          // Display an error message if the user doesn't have admin access
          toast({
            title: 'Sign-in failed',
            description: 'You do not have admin access',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error: any) {
      // Handle API errors
      handleApiError(error);
    }
  };

  // Function to handle API errors and display error messages
  const handleApiError = (error: AxiosError) => {
    // if (error.response) {
    //   // Log details of the response error
    //   console.error('Request failed with status:', error.response.status);
    //   console.error('Error data:', error.response.data);
    // } else if (error.request) {
    //   // Log an error if no response is received from the server
    //   console.error('No response received from the server');
    // } else {
    //   // Log an error if there's an issue setting up the request
    //   console.error('Error setting up the request:', error.message);
    // }

    // Display an error message using toast
    toast({
      title: 'Sign-in failed',
      description: error?.response?.statusText || 'An error occurred',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  // Event handler for email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  // Event handler for password input change
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  // CSS classes for the sign-in button
  const signInButtonClasses = "linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200";

  // Render the sign-in form
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        {/* Input fields for email and password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          onChange={handleEmailChange}
          value={email}
          placeholder="mail@simple.com"
          id="email"
          type="text"
        />

        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          onChange={handlePasswordChange}
          value={password}
        />

        {/* Checkbox for "Keep me logged in" and "Forgot Password?" link */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged in
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        {/* Sign-in button */}
        <button
          onClick={handleSignIn}
          className={signInButtonClasses}
        >
          Sign In
        </button>
      </div>
    </div>
  );
}
