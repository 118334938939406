import React, { useState } from 'react';
import logo from 'assets/img/logo.png';
import {
  FormControl,
  Stack,
  Select,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Button,
  Textarea,
  Input,
  Spinner,
  Flex
} from '@chakra-ui/react';

import { postMethod } from 'API/APIResponse';
import { createJourney } from 'API/apiList';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';


function AddJourney(props: { tableData: any; handleChange: any }) {
  const toast = useToast();
  const [journeyName, setJourneyName] = useState<string>('');
  const [journeyDescription, setDescriptionJourney] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setJourneyName(value);
  };


  const handleSave = async (e: any) => {
    try {
      setLoading(true)
      const data = {
        journeyName: journeyName,
        description: journeyDescription,
      };
      const token = localStorage.getItem('token');
      if (data) {
        const result = await postMethod(createJourney, data, token);
        if (result) {
          setJourneyName('');
          setDescriptionJourney('');
          setLoading(false)
          toast({
            title: 'Journey Created Successfully',
            description: '',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          props.handleChange(result);
        }
      }
    } catch (error: any) {
      setLoading(false)
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Journey Create Failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (<>
    {loading && (<Flex
      align="center"
      justify="center"
      height="100vh"
      direction="column"
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(255, 255, 255, 0.8)"
    >
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    </Flex>)}
    {!loading && (<>
      <Stack spacing={4} m={4} p={4}>
          <>
            <FormControl p={8} borderColor="blue" borderWidth={1} borderRadius={8} boxShadow="lg">
              <Stack mt={8} direction="row" spacing={4}>
                <Avatar size="xl" src={logo} mb={4} pos="relative" />
                <Heading fontSize="2xl" fontFamily="body">
                  Journey
                </Heading>
              </Stack>
              <Input type="text" id="journeyName" placeholder="Journey Name" value={journeyName} onChange={onChangeName} variant="journey" border="1px solid blue" mb={2} />
              <Textarea placeholder='Journey Description' value={journeyDescription} onChange={(event) => setDescriptionJourney(event.target.value)} mb={2} ></Textarea>
              {/* <Box>
                  <Input
                    type="file"
                    accept="audio/*"
                    display="none"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button leftIcon={<AddIcon />} onClick={handleFileSelect}>
                    Upload File
                  </Button> */}
              {/* </Box> */}
              <Button colorScheme="blue" onClick={handleSave} isDisabled={journeyName === ''}>
                Save Journey
              </Button>
              <FormHelperText>Inner Workout</FormHelperText>
            </FormControl>
          </>
      </Stack>

    </>)}
  </>
  );
}

export default AddJourney;
