import React from 'react';
import { Select, useChakraSelectProps } from 'chakra-react-select';


function SelectField(props: { option: any; optionOption: any; setOption: any; }) {

    const optionOneProps = useChakraSelectProps({
        value: props.option,
        options: props.optionOption,
        onChange: props.setOption,
    });


    return (<>
        <Select {...optionOneProps} />
    </>
    );
}

export default SelectField;
