import React from "react";
import { MdHome, MdBarChart, MdPerson, MdLock, MdOutlineDirectionsRun, MdSelfImprovement } from "react-icons/md";
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import Users from "views/admin/users";
import SingleUser from "views/admin/singleUser";
import Journey from "views/admin/journey";
import QuizJourney from "views/admin/quizJourney";
import SingleJourney from "views/admin/singleJourneyDay";
import SingleJourneyDayQuestion from "views/admin/singleJourneyDayQuestion";
import Meditation from 'views/admin/meditations';
import Breathwork from 'views/admin/breathwork';
import AudioAffirmation from 'views/admin/audioAffirmation';
import Reflection from 'views/admin/reflection';
import Affirmation from 'views/admin/affirmation';
import GuidedJob from 'views/admin/guidedJobs';
import CustomerSupport from 'views/admin/customerSupport';
import Notification from 'views/admin/notifications';
import SignIn from "views/auth/SignIn";
import ResetPassword from "views/auth/Reset-password";
import ResetPin from "views/auth/Reset-pin";

const commonIconStyles = "h-6 w-6";

const mainDashboardComponent = <MainDashboard />;
const usersComponent = <Users />;
const journeyComponent = <Journey />;
const quizJourneyComponent = <QuizJourney />;
const meditationComponent = <Meditation />;
const breathworkComponent = <Breathwork />;
const audioAffirmationComponent = <AudioAffirmation />;
const guidedJobsComponent = <GuidedJob />;
const reflectionComponent = <Reflection />;
const affirmationComponent = <Affirmation />;
const customerSupportComponent = <CustomerSupport />;
const notificationComponent = <Notification />;

const routes = [
  {
    name: "Dashboard",
    layout: "/",
    path: "default",
    icon: <MdHome className={commonIconStyles} />,
    component: mainDashboardComponent,
  },
  {
    name: "Data Tables",
    layout: "/",
    icon: <MdBarChart className={commonIconStyles} />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/",
    path: "profile",
    icon: <MdPerson className={commonIconStyles} />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className={commonIconStyles} />,
    component: <SignIn />,
  },
  {
    name: "Reset Password",
    layout: "/reset-password",
    path: "",
    icon: <MdLock className={commonIconStyles} />,
    component: <ResetPassword />,
  },
  {
    name: "Reset Pin",
    layout: "/reset-pin",
    path: "",
    icon: <MdLock className={commonIconStyles} />,
    component: <ResetPin />,
  },
  {
    name: "Users",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "users",
    component: usersComponent,
  },
  {
    name: "Single User",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "users/:id",
    component: <SingleUser />,
  },
  {
    name: "Journey",
    layout: "/",
    icon: <MdOutlineDirectionsRun className={commonIconStyles} />,
    path: "journey",
    component: journeyComponent,
  },
  {
    name: "Single Journey",
    layout: "/",
    icon: <MdOutlineDirectionsRun className={commonIconStyles} />,
    path: "journey/:id",
    component: <SingleJourney />,
  },
  {
    name: "QuizJourney",
    layout: "/",
    icon: <MdOutlineDirectionsRun className={commonIconStyles} />,
    path: "quiz",
    component: quizJourneyComponent,
  },
  {
    name: "Single Quiz Journey",
    layout: "/",
    icon: <MdOutlineDirectionsRun className={commonIconStyles} />,
    path: "quiz/:id",
    component: quizJourneyComponent,
  },
  {
    name: "Single Journey Day Question",
    layout: "/",
    icon: <MdOutlineDirectionsRun className={commonIconStyles} />,
    path: "journey/:id/journeyQuestion/:journeyDay",
    component: <SingleJourneyDayQuestion />,
  },
  {
    name: "Meditation",
    layout: "/",
    icon: <MdSelfImprovement className={commonIconStyles} />,
    path: "meditation",
    component: meditationComponent,
  },
  {
    name: "Breathwork",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "breathwork",
    component: breathworkComponent,
  },
  {
    name: "AudioAffirmation",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "audioAffirmation",
    component: audioAffirmationComponent,
  },
  {
    name: "Guided Journal",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "guidedJob",
    component: guidedJobsComponent,
  },
  {
    name: "Reflection",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "reflection",
    component: reflectionComponent,
  },
  {
    name: "Affirmation",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "affirmation",
    component: affirmationComponent,
  },
  {
    name: "Customer Support",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "customerSupport",
    component: customerSupportComponent,
  },
  {
    name: "Notification",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "notification",
    component: notificationComponent,
  },
];

export const sideBarRoutes = [
  {
    name: "Dashboard",
    layout: "/",
    path: "default",
    icon: <MdHome className={commonIconStyles} />,
    component: mainDashboardComponent,
  },
  {
    name: "Users",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "users",
    component: usersComponent,
  },
  {
    name: "Journey",
    layout: "/",
    icon: <MdOutlineDirectionsRun className={commonIconStyles} />,
    path: "journey",
    component: journeyComponent,
  },
  {
    name: "QuizJourney",
    layout: "/",
    icon: <MdOutlineDirectionsRun className={commonIconStyles} />,
    path: "quiz",
    component: quizJourneyComponent,
  },
  {
    name: "Reflection",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "reflection",
    component: reflectionComponent,
  },
  {
    name: "Affirmation",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "affirmation",
    component: affirmationComponent,
  },
  {
    name: "Meditation",
    layout: "/",
    icon: <MdSelfImprovement className={commonIconStyles} />,
    path: "meditation",
    component: meditationComponent,
  },
  {
    name: "Breathwork",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "breathwork",
    component: breathworkComponent,
  },
  {
    name: "AudioAffirmation",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "audioAffirmation",
    component: audioAffirmationComponent,
  },
  {
    name: "Guided Journal",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "guidedJob",
    component: guidedJobsComponent,
  },
  {
    name: "Customer Support",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "customerSupport",
    component: customerSupportComponent,
  },
  {
    name: "Notification",
    layout: "/",
    icon: <MdPerson className={commonIconStyles} />,
    path: "notification",
    component: notificationComponent,
  },
];

export default routes;