import React, { useContext, useEffect, useMemo } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import { Context } from "context/context";
import { getMethod } from "API/APIResponse";
import { fetchAllUsers, fetchJourney, fetchReflection } from "API/apiList";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  // console.log(props)
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const [userName, setUserName] = React.useState("user");
  const location = useLocation();
  const [route, setRoute] = React.useState("");
  const {
    search,
    setSearch,
    setInitialRender,
    // data,
    // setCurrentItems,
    // currentPage,
    // setCurrentPage,
    // totalPages,
    // setTotalPage,
    // itemsPerPage,
    // setItemPerPage,
    // indexOfLastItem,
    // setindexOfLastItem,
  } = useContext(Context);
  // const navigate = useNavigate();
  // const toast = useToast();
  // let url = "";

  useMemo(() => {
    setSearch("");
    setInitialRender(true);
    setRoute(location.pathname);
    // setTotalPage(0);
    // setCurrentPage(1);
    // setindexOfLastItem(0);
    // setItemPerPage(5);
    // setCurrentItems([]);
    // setRoute(location.pathname);
  }, [location.pathname]);

  // if (route === "/journey") url = fetchJourney;
  // if (route === "/reflection") url = fetchReflection;

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   setUserName(user?.name);

  //   const timeout = setTimeout(() => {
  //     (async () => {
  //       try {
  //         const token = localStorage.getItem("token");
  //         url += `?page=${1}&limit=10`;
  //         url += search ? `&search=${search}` : ``;
  //         console.log(url)
  //         const result: any = await getMethod(url, {}, token);
  //         if (result) {
  //           // console.log(result);
  //           const { data } = result;
  //           if (data) {
  //             const { results, totalPages, page, limit, totalResults } = data;
  //             setTotalPage(totalPages);
  //             setCurrentPage(page);
  //             setindexOfLastItem(totalResults);
  //             setItemPerPage(limit);
  //             setCurrentItems(results);
  //           }
  //         }
  //       } catch (error: any) {
  //         const axiosError: AxiosError = error;
  //         if (axiosError.response.status === 401) {
  //           localStorage.clear();
  //           navigate("/auth/sign-in");
  //         }
  //         toast({
  //           title: "Users List failed",
  //           description: axiosError?.response?.statusText,
  //           status: "error",
  //           duration: 5000,
  //           isClosable: true,
  //         });
  //       }
  //     })();
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [search]);

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div
        className={`relative mt-[3px] flex h-[61px] ${
          route === "/affirmation" ||
          route === "/reflection" ||
          route === "/profile" ||
          route === "/default" ||
          route === "/notification"
            ? "w-[150px]"
            : "w-[355px] flex-grow md:w-[365px] xl:w-[365px]"
        } items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1  xl:gap-2`}
      >
        <div
          className={`${
            route === "/affirmation" ||
            route === "/reflection" ||
            route === "/profile" ||
            route === "/default" ||
            route === "/notification"
              ? "hidden"
              : "flex"
          } h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]`}
        >
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            name="search"
            value={search}
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            onChange={(e) => {
              setSearch(e.target.value);
              setInitialRender(false);
            }}
          />
        </div>

        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-48 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {userName}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 flex flex-col">
                <Link
                  to="/profile"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>
                <a
                  href="/auth"
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
