import React, { useState } from "react";
import Card from "components/card";
import ListQuizJourney from "./components/ListQuizJourney";
import AddQuizJourney from "./components/AddQuizJourney";
import tableDataDevelopment from "./variables/tableDataDevelopment";
import { Button, Grid} from "@chakra-ui/react";

const Tables = () => {
  const [addQuizJourney, setAddQuizJourney] = useState(false);

  const handleChange = () => {
    setAddQuizJourney(!addQuizJourney);
  };

  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Quiz Journey List
          </div>

          {addQuizJourney === true ? <></> : 
          <Grid  className='buttonRow' gap={2}>
            
          <Button colorScheme="blue" onClick={handleChange}>
            Add Quiz Journey
          </Button>
          </Grid>}
        </header>

        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addQuizJourney ? (
            <AddQuizJourney tableData={tableDataDevelopment} handleChange={handleChange} />
          ) : (
            <ListQuizJourney tableData={tableDataDevelopment} />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Tables;
