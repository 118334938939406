import Card from "components/card";
import ListJourney from "./components/ListJourneyQuestion";
import AddJourney from "./components/AddQuestion";
import { useEffect, useState } from "react";
import { getMethod } from "API/APIResponse";
import { fetchJourneyDay } from "API/apiList";
import { AxiosError } from "axios";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Tables = () => {
  const [addJourney, setAddJourney] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const handleChange = () => {
    setAddJourney(!addJourney);
  };

  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem('token');
      const result: any = await getMethod(fetchJourneyDay + `/${window.location.href.split('journeyQuestion/')[1]}`, {}, token);
      if (result) {
        const { data } = result;
        if (data) {

        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Journey List Fetch failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
          Guided Journals
          </div>
          {addJourney === true ? <></> : <Button onClick={handleChange} colorScheme="blue">Add Question</Button>}
        </header>

        {addJourney === true ? <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <AddJourney handleChange={handleChange} />
        </div> :
          <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
            <ListJourney />
          </div>
        }
      </Card>
    </div>
  );
};

export default Tables;
