import React, { useContext, useEffect, useState } from "react";
import EditQuestion from "./EditQuestion";
import DeleteQuestion from "./DeleteQuestion";
import { Box, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { AxiosError } from "axios";
import { useToast, Button } from "@chakra-ui/react";
import { getMethod } from "API/APIResponse";
import { fetchJourneyDayQuestion } from "API/apiList";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import { Context } from "context/context";

interface TableData {
  id: number;
  question: string;
  // Add more data properties as needed
}

function ListJourney() {
  const { search, initialRender } = useContext(Context);
  const toast = useToast();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [totalPages, setTotalPage] = useState(0);
  const [editQuestion, setEditQuestion] = useState(false);
  const [itemData, setItemData] = useState({});
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [itemDataID, setItemDataId] = useState<string>(null);
  const handleEditChange = (item: any) => {
    setEditQuestion(!editQuestion);
    setItemData(item);
    setDeleteModal(false);
    fetchData(currentPage);
  };

  const closeModal = () => {
    setItemDataId(null);
    setDeleteModal(false);
  };
  const handleDelete = (id: any) => {
    setItemDataId(id);
    setEditQuestion(false);
    setDeleteModal(true);
  };
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const updateData = () => {
    fetchData(currentPage);
    setItemDataId(null);
    setDeleteModal(false);
  };
  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      let url = `${fetchJourneyDayQuestion}?journeyDayId=${
        window.location.href.split("journeyQuestion/")[1]
      }&page=${page}`;
      url += search ? `&search=${search}` : ``;
      const result: any = await getMethod(url, {}, token);
      if (result) {
        // console.log(result.data);
        const { data } = result;
        if (data) {
          // console.log(data);
          const { results, totalPages, page } = data;
          setTotalPage(totalPages);
          setCurrentPage(page);
          setCurrentItems(results);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Journey List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (initialRender) {
      fetchData();
    } else {
      const timeout = setTimeout(() => {
        fetchData();
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [search]);

  return (
    <div>
      {deleteModal && !editQuestion && (
        <DeleteQuestion
          updateData={updateData}
          itemDataID={itemDataID}
          closeModal={closeModal}
          isModal={deleteModal}
        />
      )}
      {editQuestion ? (
        <EditQuestion handleChange={handleEditChange} data={itemData} />
      ) : (
        <>
          <Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Question</Th>
                  <Th>Action</Th>
                  {/* Add more table headers for additional data */}
                </Tr>
              </Thead>
              <Tbody>
                {data.map((item) => (
                  <Tr key={item.id}>
                    <Td>{item.id}</Td>
                    <Td>{item.question}</Td>
                    <Td className="buttonRow">
                      <Button
                        className="editButton"
                        onClick={(e) => {
                          handleEditChange(item);
                        }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Td>

                    {/* Add more table data for additional properties */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Box display="flex" justifyContent="center" mt={4} mb={4}>
              {Array.from({ length: totalPages }).map((_, index) => (
                <Box
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  cursor="pointer"
                  fontWeight={index + 1 === currentPage ? "bold" : "normal"}
                  mx={2}
                >
                  <Button color="blue">{index + 1}</Button>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}

export default ListJourney;
