import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  useContext,
} from "react";
import { getMethod, patchMethod } from "API/APIResponse";
import { fetchExplore, updateExploreData } from "API/apiList";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import DeleteMeditation from "./DeleteMeditation";
import {
  Box,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  List,
  ListItem,
  Text,
  Input,
  FormControl,
  Select,
  FormLabel,
  Spinner,
  Flex,
  Switch,
} from "@chakra-ui/react";

import { ViewIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { AddIcon } from "@chakra-ui/icons";

import { log } from "util";
import { Context } from "context/context";
interface TableData {
  id: string;
  name: string;
  dimension: string;
  desiredEndState: string;
  duration: number;
  isListening: boolean;
  isPrompt: boolean;
  promptText: string;
  isMeditation: boolean;
  files: string;
  // Add more data properties as needed
}
const apiUrl = process.env.REACT_APP_API_URL;
const listing = {
  display: "flex",
  alignItems: "center",
};

function ListMeditation(props: {}) {
  const { search,initialRender } = useContext(Context);
  const navigate = useNavigate();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const quizArray = [
    "Bliss",
    "Wisdom",
    "Mental & emotional",
    "Energetic",
    "Physical",
  ];

  const stateArrayValues = ["Grow", "Focus", "Relax", "Sleep"];

  const [itemsPerPage, setItemPerPage] = useState<number>(5);
  // const [dimension, setDimension] = useState<s>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPage] = useState<number>(0);
  const [indexOfLastItem, setindexOfLastItem] = useState<number>(0);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [isView, setIsView] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [itemData, setItemData] = useState<TableData | null>(null);
  const [name, setName] = useState<string>("");
  const [dimension, setDimension] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [files, setFiles] = useState<string>("");
  const [promptText, setPromptText] = useState<string>("");
  const [isListening, setIsListening] = useState<string>("false");
  const [isMeditation, setMeditation] = useState<boolean>(true);
  const [isPrompt, setIsPrompt] = useState<string>("false");
  const [desiredEndState, setDesiredEndState] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [optionArr, setOptionArr] = useState<string[]>(stateArrayValues);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [itemDataID, setItemDataId] = useState<string>(null);
  const [loading, setLoading] = useState(false);
  const [playFile, setPlayFile] = useState<string | null>(null);

  const fetchData = async (page = 1) => {
    const token = localStorage.getItem("token");
    try {
      let url = `${fetchExplore}?limit=${itemsPerPage}&page=${page}&exploreType=Meditation`;
      url += search ? `&search=${search}` : ``;
      const result: any = await getMethod(
        url,
        {},
        token
      );
      if (result) {
        const { data } = result;
        if (data) {
          const { results, totalPages, page, limit, totalResults } = data;
          setCurrentItems(results);
          setTotalPage(totalPages);
          setCurrentPage(page);
          setindexOfLastItem(totalResults);
          setItemPerPage(limit);
          // setPlayFile(URL.createObjectURL(results));
          //    setCurrentItems(result)
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Journey List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateMeditation = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("dimension", dimension);
      formData.append("duration", duration.toString());
      formData.append("promptText", promptText);
      formData.append("desiredEndState", desiredEndState);
      formData.append("isListening", isListening);
      formData.append("isMeditation", isMeditation.toString());
      formData.append("isPrompt", isPrompt);
      formData.append(
        "files",
        fileInputRef.current!.files[0] ? fileInputRef.current!.files[0] : ""
      );
      const result: any = await patchMethod(
        updateExploreData + `/${itemData?.id}`,
        formData,
        token
      );
      if (result) {
        setLoading(false);
        fetchData(currentPage);
        setIsEdit(false);
        setModalOpen(false);
        setItemData(null);
        setSelectedFile(null);
        toast({
          title: "Meditation Updated Successfully",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      setLoading(false);
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Meditaion List failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const handleEdit = (item: TableData) => {
    setItemData(item);
    setName(item.name);
    setDimension(item.dimension);
    setDuration(item.duration);
    setFiles(item.files[0]);
    setPromptText(item.promptText);
    setDesiredEndState(item.desiredEndState);
    setIsPrompt(item.isPrompt.toString());
    setIsListening(item.isListening.toString());
    setIsView(true);
    setDeleteModal(false);
    setModalOpen(true);
    setIsEdit(true);
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDemention = (e: any) => {
    const dimentionValue = e.target.value;
    setDimension(dimentionValue);
    setOptionArr(stateArrayValues);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file.name);
        setPlayFile(URL.createObjectURL(file));
      } else {
        setSelectedFile(null);
      }
    }
  };

  const handleMeditation = (item: TableData) => {
    setIsEdit(false);
    setIsView(true);
    setDeleteModal(false);
    setModalOpen(true);
    setItemData(item);
  };

  const closeModal = () => {
    setItemDataId(null);
    setDeleteModal(false);
  };

  const deleteMeditationMethod = (id: string) => {
    setItemDataId(id);
    setIsView(false);
    setDeleteModal(true);
  };

  const updateData = () => {
    fetchData(currentPage);
  };

  useEffect(() => {
    if(initialRender){
      fetchData();
    }else{
      const timeout = setTimeout(() => {
        fetchData();
      }, 500);
  
      return ()=>{
        clearTimeout(timeout)
      }
    }
  }, [props, search]);

  return (
    <>
      {loading && (
        <Flex
          align="center"
          justify="center"
          height="100vh"
          direction="column"
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}

      {!loading && (
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Meditation Name</Th>
                <Th>Dimension</Th>
                <Th>Desired End State</Th>
                <Th>Prompt Text</Th>
                <Th>Duration</Th>
                <Th>File</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.id}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.dimension}</Td>
                  <Td>{item.desiredEndState}</Td>
                  <Td>{item.promptText}</Td>
                  <Td>{item.duration}</Td>
                  <Td>
                    <audio controls>
                      <source src={apiUrl + item.files[0]} type="audio/mp3" />
                    </audio>
                  </Td>
                  <Td>
                    <div className="buttonRow">
                      <Button
                        colorScheme="blue"
                        onClick={() => handleMeditation(item)}
                      >
                        <ViewIcon />
                      </Button>
                      <Button
                        className="editButton"
                        onClick={() => handleEdit(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => deleteMeditationMethod(item.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box display="flex" justifyContent="center" mt={4}>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Box
                key={index}
                onClick={() => handlePageChange(index + 1)}
                cursor="pointer"
                fontWeight={index + 1 === currentPage ? "bold" : "normal"}
                mx={2}
              >
                <Button color="blue">{index + 1}</Button>
              </Box>
            ))}
          </Box>
          {deleteModal && !isView && (
            <DeleteMeditation
              updateData={updateData}
              itemDataID={itemDataID}
              closeModal={closeModal}
              isModal={deleteModal}
            />
          )}
          {isView && (
            <Modal
              isOpen={isModalOpen}
              onClose={() => {
                setModalOpen(false);
                setIsView(false);
              }}
            >
              <ModalOverlay />
              <ModalContent>
                {isEdit ? (
                  <ModalHeader color="#F1B452">Update Meditation</ModalHeader>
                ) : (
                  <ModalHeader>Meditation Details</ModalHeader>
                )}
                <ModalCloseButton />
                <ModalBody>
                  <Box>
                    <List spacing={3}>
                      {itemData && (
                        <>
                          <FormControl>
                            <FormLabel color="blue.500">Id :</FormLabel>{" "}
                            {itemData?.id}
                          </FormControl>
                          <FormControl>
                            <FormLabel color="blue.500" htmlFor="name">
                              Name :
                            </FormLabel>
                            {isEdit ? (
                              <Input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            ) : (
                              itemData?.name
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel color="blue.500" htmlFor="dimension">
                              Dimension :
                            </FormLabel>{" "}
                            {isEdit ? (
                              <Select
                                id="dimension"
                                value={dimension}
                                onChange={(e) => handleDemention(e)}
                                required
                              >
                                {quizArray &&
                                  quizArray.map((e) => (
                                    <option key={e} value={e}>
                                      {e}
                                    </option>
                                  ))}
                              </Select>
                            ) : (
                              itemData?.dimension
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel
                              color="blue.500"
                              htmlFor="desiredEndState"
                            >
                              Desired End State :
                            </FormLabel>{" "}
                            {isEdit ? (
                              <Select
                                id="desiredEndState"
                                value={desiredEndState}
                                onChange={(e) =>
                                  setDesiredEndState(e.target.value)
                                }
                                required
                              >
                                {optionArr &&
                                  optionArr.map((e) => (
                                    <option key={e} value={e}>
                                      {e}
                                    </option>
                                  ))}
                              </Select>
                            ) : (
                              itemData?.desiredEndState
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel color="blue.500" htmlFor="duration">
                              {" "}
                              Duration: :{" "}
                            </FormLabel>{" "}
                            {isEdit ? (
                              <Input
                                type="text"
                                value={duration}
                                onChange={(e) =>
                                  setDuration(
                                    e.target.value
                                      ? parseInt(e.target.value)
                                      : 0
                                  )
                                }
                              />
                            ) : (
                              itemData?.duration
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel color="blue.500" htmlFor="isListening">
                              isListening :{" "}
                            </FormLabel>
                            {isEdit ? (
                              <Input
                                type="text"
                                value={isListening}
                                onChange={(e) => setIsListening(e.target.value)}
                              />
                            ) : itemData?.isListening ? (
                              "true"
                            ) : (
                              "false"
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel color="blue.500" htmlFor="isPrompt">
                              isPrompt :{" "}
                            </FormLabel>
                            {isEdit ? (
                              <Input
                                type="text"
                                value={isPrompt}
                                onChange={(e) => setIsPrompt(e.target.value)}
                              />
                            ) : itemData?.isPrompt ? (
                              "true"
                            ) : (
                              "false"
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel color="blue.500" htmlFor="promptText">
                              promptText:{" "}
                            </FormLabel>
                            {isEdit ? (
                              <Textarea
                                value={promptText}
                                onChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>
                                ) => setPromptText(e.target.value)}
                              />
                            ) : (
                              itemData?.promptText
                            )}
                          </FormControl>
                          <FormControl>
                            <Text color="blue.500">File: </Text>
                            {isEdit ? (
                              <Box>
                                <Input
                                  type="file"
                                  accept="audio/*"
                                  required
                                  display="none"
                                  ref={fileInputRef}
                                  onChange={handleFileChange}
                                />
                                <Button
                                  leftIcon={<AddIcon />}
                                  onClick={handleFileSelect}
                                >
                                  Upload File
                                </Button>
                                {selectedFile ? (
                                  <>
                                    <Text
                                      display="flex"
                                      flexDirection={{
                                        base: "column",
                                        md: "row",
                                      }}
                                    >
                                      Selected File: {selectedFile}
                                    </Text>
                                    <audio controls style={{ width: "50%" }}>
                                      <source src={playFile} type="audio/mp3" />
                                    </audio>
                                  </>
                                ) : (
                                  <>
                                    <Text>{itemData?.files}</Text>
                                    <audio controls>
                                      <source
                                        src={apiUrl + itemData.files[0]}
                                        type="audio/mp3"
                                      />
                                    </audio>
                                  </>
                                )}
                              </Box>
                            ) : (
                              <audio controls>
                                <source
                                  src={apiUrl + itemData.files[0]}
                                  type="audio/mp3"
                                />
                              </audio>
                            )}
                          </FormControl>
                        </>
                      )}
                    </List>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  {isEdit ? (
                    <>
                      <Button
                        color="#F1B452"
                        mr={3}
                        onClick={() => updateMeditation()}
                      >
                        Submit
                      </Button>
                      <Button
                        colorScheme="gray"
                        mr={3}
                        onClick={() => {
                          setModalOpen(false);
                          setIsView(false);
                        }}
                      >
                        Close
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="ghost"
                      mr={3}
                      onClick={() => {
                        setModalOpen(false);
                        setIsView(false);
                      }}
                    >
                      Close
                    </Button>
                  )}
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
          <br />
        </Box>
      )}
    </>
  );
}
export default ListMeditation;
