import React, { useState } from "react";
import {
  FormLabel,
  Input,
  Button,
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
} from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import { postMethod } from "API/APIResponse";
import { createJourneyDayQuestion } from "API/apiList";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

function AddJourney(props: { handleChange: any }) {
  const toast = useToast();
  const [inputs, setInputs] = useState<string>(""); // Initialize with one input field
  const [hint, setHints] = useState<string>(""); // Initialize with two hints
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const data = {
        question: inputs === "" ? null : inputs,
        journeyDayId: window.location.href.split("journeyQuestion/")[1],
        hint: hint === "" ? undefined : hint,
      };
      const token = localStorage.getItem("token");
      if (data) {
        const result = await postMethod(createJourneyDayQuestion, data, token);
        if (result) {
          toast({
            title: 'Journey Days Created Successfully',
            description: '',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          props.handleChange(result);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Journey Days Create Failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <FormControl
        p={8}
        borderColor="blue"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
      >
        <Stack mt={8} direction="row" spacing={4}>
          <Avatar size="xl" src={logo} mb={4} pos="relative" />
        </Stack>
        <FormControl display="flex" alignItems="center" gap="4px">
          <FormLabel htmlFor={`question`} mb="0">
            <Heading fontSize="m" fontFamily="body">
              Question
            </Heading>
          </FormLabel>
          <Input
            type="text"
            value={inputs}
            onChange={(e) => setInputs(e.target.value)}
          />
          <FormLabel htmlFor={`question`} mb="0">
            <Heading fontSize="m" fontFamily="body">
              Hint
            </Heading>
          </FormLabel>
          <Input
            type="text"
            value={hint}
            onChange={(e) => setHints(e.target.value)}
          />
          <Button bg="blue.100" p={5} onClick={() =>{
            setHints("");
            setInputs("");
          }
          }>
            Remove
          </Button>
        </FormControl>

        <FormHelperText>Inner Workout</FormHelperText>
      </FormControl>
      <Button onClick={handleSubmit} colorScheme="blue">
        Save
      </Button>
      <br />
    </>
  );
}

export default AddJourney;
