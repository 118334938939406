import React, { useEffect, useState  } from "react";
import { useToast } from "@chakra-ui/react";
import {  deleteMethod } from "API/APIResponse";
import { AxiosError } from "axios";
import { deleteJourneyDayQuestion } from "API/apiList";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
  } from "@chakra-ui/react";

  function DeleteQuestion(props: {updateData:any , closeModal:any, itemDataID:string , isModal:boolean } ) {
    const toast = useToast();
    const [isModalOpen, setModalOpen] = useState(props.isModal);

    const handleDelete = async() =>{
try {
    const token = localStorage.getItem("token");
    const result: any = await deleteMethod(
        deleteJourneyDayQuestion + `/${props.itemDataID}`,
        {},
        token
      );
      if (result) {
        setModalOpen(false)
        props.updateData()
         toast({
            title: "Journey Day Question deleted Successfully",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          //    setCurrentItems(result)
      }
} catch (error:any) {
    const axiosError: AxiosError = error;
      toast({
        title: "Journey Day Question deleted failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
}
    }

    return(
        <>
 <Modal isOpen={isModalOpen} onClose={() => props.closeModal()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Question</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <Text color="red ">Do You want to Delete Question</Text> 
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => props.closeModal()}>
              NO
            </Button>
            <Button variant='ghost' onClick={() => handleDelete() } >Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        </>
    )
  }

  export default DeleteQuestion;