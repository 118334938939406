import React, { useEffect, useState } from "react";
import Card from "components/card";
import ListReflection from "./components/ListReflection";
import { Grid, Select } from "@chakra-ui/react";
import AddReflection from "./components/AddReflection";
import tableDataDevelopment from "./variables/tableDataDevelopment";
import { Button } from "@chakra-ui/react";

const Tables = () => {
  const [addReflection, setAddReflection] = useState(false);
  const [reflectionName, setReflectioName] = useState("morning");

  const handleChange = () => {
    setAddReflection(!addReflection);
  };
  useEffect(() => {}, [reflectionName]);

  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {addReflection ? "Add Reflection" : "Reflection List"}
          </div>
          <Grid gap={2}>
            <Select
              defaultValue={"morning"}
              placeholder="Select option"
              value={reflectionName}
              onChange={(e) => setReflectioName(e.target.value)}
            >
              <option value="morning">Morning</option>
              <option value="afternoon">Afternoon</option>
              <option value="evening">Evening</option>
            </Select>
            {addReflection === true ? (
              <></>
            ) : (
              <Button colorScheme="blue" onClick={handleChange}>
                Add Question
              </Button>
            )}
          </Grid>
        </header>

        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addReflection ? (
            <AddReflection
              tableData={tableDataDevelopment}
              reflectionName={reflectionName}
              handleChange={handleChange}
            />
          ) : (
            <ListReflection
              tableData={tableDataDevelopment}
              reflectionName={reflectionName}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Tables;
