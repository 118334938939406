import React, { useContext, useEffect, useState } from "react";
// import Table from 'react-bootstrap/Table';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Input,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import Card from "components/card";

import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { deleteMethod, getMethod, patchMethod } from "API/APIResponse";
import {
  deleteReflectionDayQuestion,
  fetchReflection,
  updateReflectionQuestion,
} from "API/apiList";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import { CheckIcon, DeleteIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import { Context } from "context/context";

interface TableData {
  id: number;
  question: string;
  hint: string;
  suggestions: string[];
  // Add more data properties as needed
}

function ListReflection(props: { tableData: any; reflectionName: string }) {
  // const { tableData } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [totalPages, setTotalPage] = useState(0);
  const [edit, setEdit] = useState(null);
  const [reflectionId, setReflectionId] = useState(null);
  const [editQuestion, setEditQuestion] = useState(null);
  const [editHint, setEditHint] = useState(null);
  const [suggestions, setSuggestions] = useState<Array<String>>([""]);
  // const [indexOfFirstItem, setindexOfFirstItem] = useState(0);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const editReflection = (data: any) => {
    setEdit(data);
    setEditQuestion(data.question);
    setEditHint(data.hint);
    setSuggestions(data.suggestions);
  };
  
  const deleteReflectionMethod = async (id: any) => {
    try {
      const token = localStorage.getItem("token");
      const result: any = await deleteMethod(
        deleteReflectionDayQuestion + `/${id}`,
        {},
        token
      );
      if (result) {
        await fetchData(currentPage);
        toast({
          title: "Reflection Delete failed",
          description: `Reflection id ${id} deleted successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Reflection Delete failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const saveReflection = async (data: any) => {
    const updatedSuggestions = suggestions.filter((data) => data !== "");

    const saveData = {
      reflectionId: reflectionId,
      question: editQuestion,
      hint: editHint,
      suggestions: updatedSuggestions,
    };
    try {
      const token = localStorage.getItem("token");
      if (saveData) {
        const result = await patchMethod(
          updateReflectionQuestion + `/${data.id}`,
          saveData,
          token
        );
        if (result) {
          toast({
            title: "Reflection Question Updated Successfully",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setEdit(false);
          fetchData();
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Reflection Delete failed",
        description: error?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchData = async (page = 1) => {
    try {
      if (props.reflectionName) {
        const token = localStorage.getItem("token");
        let url = `${fetchReflection}?reflectionName=${props.reflectionName}`;
        // url += search ? `&search=${search}` : ``;
        const result: any = await getMethod(url, {}, token);
        if (result) {
          if (result.data) {
            const { data } = result;
            setReflectionId(data.id);
            if (data.reflectionQuestion) {
              setCurrentItems(data.reflectionQuestion);
              setTotalPage(1);
            } else {
              setCurrentItems([]);
              setTotalPage(0);
            }
          }
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Reflection List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateSuggestions = (index: number, data: string) => {
    const updatedSuggestions = [...suggestions];
    updatedSuggestions[index] = data;
    setSuggestions(updatedSuggestions);
  };

  const handleDeleteSuggestion = (index: number) => {
    const updatedSuggestions = suggestions.filter((data, key) => key !== index);
    setSuggestions(updatedSuggestions);
  };

  useEffect(() => {
    fetchData();
  }, [props.reflectionName]);

  return (
    <div>
      <Card>
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Question</Th>
                <Th>Hint</Th>
                <Th>Prompts</Th>
                <Th>Actions</Th>
                {/* Add more table headers for additional data */}
                
              </Tr>
            </Thead>
            <Tbody>
              {data.length > 0 &&
                data.map((item) => {
                  if (edit) {
                    if (edit.id === item.id) {
                      return (
                        <Tr key={item.id}>
                          <Td>{item.id}</Td>
                          <Td>
                            <Input
                              value={editQuestion}
                              onChange={(e) => setEditQuestion(e.target.value)}
                              type="text"
                            />
                          </Td>
                          <Td>
                            <Input
                              value={editHint}
                              type="text"
                              onChange={(e) => setEditHint(e.target.value)}
                            />
                          </Td>
                          <Td>
                            {suggestions.map((data, index) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Input
                                    value={data.toString()}
                                    type="text"
                                    style={{ margin: "5px" }}
                                    onChange={(e) => {
                                      updateSuggestions(index, e.target.value);
                                    }}
                                  ></Input>
                                  <DeleteIcon
                                    onClick={() => {
                                      handleDeleteSuggestion(index);
                                    }}
                                  />
                                </div>
                              </>
                            ))}
                            {suggestions.length > 0 && (
                              <Button
                                colorScheme="teal"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  setSuggestions([...suggestions, ""]);
                                }}
                              >
                                Add More Suggestions
                              </Button>
                            )}
                          </Td>
                          <Td>
                            {" "}
                            <div className="buttonRow">
                              <Button
                                className="editButton"
                                onClick={() => saveReflection(item)}
                              >
                                <CheckIcon />
                              </Button>
                            </div>
                          </Td>
                        </Tr>
                      );
                    }
                    return (
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>
                        <Td>{item.question}</Td>
                        <Td>{item.hint}</Td>
                        <Td>
                          <UnorderedList>
                            {item.suggestions.map((data) => (
                              <ListItem>{data}</ListItem>
                            ))}
                          </UnorderedList>
                        </Td>
                        <Td>
                          {" "}
                          <div className="buttonRow">
                            <Button
                              className="editButton"
                              onClick={() => editReflection(item)}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() => deleteReflectionMethod(item.id)}
                            >
                              <DeleteIcon />
                            </Button>
                          </div>
                        </Td>
                      </Tr>
                    );
                  } else {
                    return (
                      <Tr key={item.id}>
                        <Td>{item.id}</Td>
                        <Td>{item.question}</Td>
                        <Td>{item.hint}</Td>
                        <Td>
                          <UnorderedList>
                            {item.suggestions.map((data) => (
                              <ListItem>{data}</ListItem>
                            ))}
                          </UnorderedList>
                        </Td>
                        <Td>
                          {" "}
                          <div className="buttonRow">
                            <Button
                              className="editButton"
                              onClick={() => editReflection(item)}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              colorScheme="red"
                              onClick={() => deleteReflectionMethod(item.id)}
                            >
                              <DeleteIcon />
                            </Button>
                          </div>
                        </Td>
                      </Tr>
                    );
                  }
                })}
            </Tbody>
          </Table>
        </Box>
        {/* 
        (
                {edit && edit.id === item.id ? 
                 <Tr key={item.id}>
                  <Td>{item.id}</Td>
                  <Td><Input type="text" value={edit.question} ></Input><Td>
                  <Td>{item.hint}</Td>
                  <Td> <div className="buttonRow">
                    <Button className="editButton" onClick={() => editReflection(item)}><EditIcon /></Button>
                    <Button colorScheme="red" onClick={() => deleteReflectionMethod(item.id)}><DeleteIcon /></Button>
                  </div></Td>
                </Tr> : 
                 <Tr key={item.id}>
                  <Td>{item.id}</Td>
                  <Td>{item.question}</Td>
                  <Td>{item.hint}</Td>
                  <Td> <div className="buttonRow">
                    <Button className="editButton" onClick={() => editReflection(item)}><EditIcon /></Button>
                    <Button colorScheme="red" onClick={() => deleteReflectionMethod(item.id)}><DeleteIcon /></Button>
                  </div></Td>

                </Tr>
                }
              ) */}
      </Card>
      <Box display="flex" justifyContent="center" mt={4} mb={4}>
        {Array.from({ length: totalPages }).map((_, index) => (
          <Box
            key={index}
            onClick={() => handlePageChange(index + 1)}
            cursor="pointer"
            fontWeight={index + 1 === currentPage ? "bold" : "normal"}
            mx={2}
          >
            <Button color="blue">{index + 1}</Button>
          </Box>
        ))}
      </Box>
    </div>
  );
}

export default ListReflection;
