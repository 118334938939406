import React, { useEffect } from "react";
import Card from "components/card";
import ListCustomerSupport from "./components/ListCustomerSupport";
import { Grid } from '@chakra-ui/react' 

const Tables = () => {
 
  useEffect(() => {
  }, []);


  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
          </div>
          <Grid gap={2}> 
          </Grid>
        </header>

        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">          
            <ListCustomerSupport />
        </div>
      </Card>
    </div>
  );
};

export default Tables;
