import React from 'react';

export const Context = React.createContext({
    search:"",setSearch:(newData: any)=>{},initialRender:true,setInitialRender:(newData: any)=>{}
    // data:[],setCurrentItems:(newData: any[])=>{},
    // currentPage:1, setCurrentPage:(newData: any)=>{}, 
    // totalPages:0,setTotalPage:(newData: any)=>{},
    // itemsPerPage:5, setItemPerPage:(newData: any)=>{},
    // indexOfLastItem:0, setindexOfLastItem:(newData: any)=>{}
});

