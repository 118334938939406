import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button } from "@chakra-ui/react";
import ListNotification from "./components/ListNotification";
import "./style.css";
import ListUser from "./components/ListUser";

const Notifications = () => {
  const [update, setupdateNoti] = useState(false);
  const [selection, setSelection] = useState<string>(null);
  const [users, setUsers] = useState<Boolean>(false);
  
  const handleChange = (data: string) => {
    return () => {
      setSelection(data);
    };
  };
 
  const handleUser = ()=>{
    setupdateNoti(!update);
  }
  

  useEffect(() => {
  }, []);

  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white"> 
            {
              users ? "All Users" :"All Notifications"
            }
          </div>
           <div className='displayFlex'>
            <Button onClick={handleUser} colorScheme="blue" marginRight={4} background={"#3182ce"}>
             { !update ? "Update Notifications" : "Custom Notifications" }
            </Button>
          </div>
        </header>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        {
          update ? (<ListNotification selected=""/>):(<ListUser/>)
        }
        </div>
      </Card>
    </div>
  );
};

export default Notifications;
