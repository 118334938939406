import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import {
  Textarea,
  FormLabel,
  Switch,
  Input,
  Button,
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Tooltip,
  Select,
  Flex,
  Text,
  Box,
  Spinner
} from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import { AddIcon } from "@chakra-ui/icons";
import { AxiosError } from "axios";
import { createExplore } from "API/apiList";
import { postMethod } from "API/APIResponse";
import { useNavigate } from "react-router-dom";

function AddAudioAffirmation(props: {handleChange:any}) {
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const quizArray = ["Bliss", "Wisdom", "Mental & emotional", "Energetic", "Physical"];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stateArrayValues = ["Grow", "Focus", "Relax", "Sleep"];

  const [name, setName] = useState<string>("");
  const [dimension, setDimension] = useState<string>("");
  const [desiredEndState, setDesiredEndState] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [playFile, setPlayFile] = useState<string | null>(null);
  const [optionArr, setOptionArr] = useState<string[]>(stateArrayValues);
  const [loading, setLoading] = useState(false);
  const [isListening, setListening] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file.name);
        setPlayFile(URL.createObjectURL(file));
      } else {
        setSelectedFile(null);
      }
    }
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // useEffect(() => {
  //   setDimension('Bliss');
  //   setOptionArr(stateArrayValues);
  //   setDesiredEndState(stateArrayValues[0]);
  // },[stateArrayValues]);

  const handleDemention = (e: any) => {
    const dimentionValue = e.target.value;
    setDimension(dimentionValue);
    setOptionArr(stateArrayValues);
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("exploreType", 'Affirmation');
      formData.append("duration", duration.toString());
      formData.append("dimension", dimension);
      formData.append("isListening",isListening.toString());
      formData.append("desiredEndState", desiredEndState);
      formData.append("files", fileInputRef.current!.files[0]);
      if (formData) {
        const result = await postMethod(createExplore, formData, token);
        if (result) {
          setLoading(false)
          toast({
            title: "AudioAffirmation Created Successfully",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
           props.handleChange();
        }
      }
    } catch (error: any) {
      setLoading(false)
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: "AudioAffirmation Create Failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
   <>
   {loading && (<Flex
      align="center"
      justify="center"
      height="100vh"
      direction="column"
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(255, 255, 255, 0.8)" 
    >
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    </Flex>)}

  {!loading && ( <>
      <FormControl
        p={8}
        borderColor="gray"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
      >
        <Stack mt={8} direction="row" spacing={4}>
          <Avatar size="xl" src={logo} mb={4} pos="relative" />
        </Stack>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="name" mb="0">
            <Heading fontSize="xl" fontFamily="body">
              AudioAffirmation Name
            </Heading>
          </FormLabel>
          <Input
            type="text"
            required
            placeholder="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <br />
        <Box display='flex' alignItems="center" gap={4} >
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="dimension" mb="0">
              <Heading fontSize="xl" fontFamily="body">
                Dimension
              </Heading>
            </FormLabel>
            <Select
              id="dimension"
              value={dimension}
              onChange={(e) => handleDemention(e)}
              required
            >
              {quizArray && quizArray.map((e) =>
                <option key={e} value={e}>{e}</option>
              )}
            </Select>
          </FormControl>
          <br />
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="desiredEndState" mb="0">
              <Heading fontSize="xl" fontFamily="body">
                Desired End State
              </Heading>
            </FormLabel>
            <Select
              id="desiredEndState"

              value={desiredEndState}
              onChange={(e) => setDesiredEndState(e.target.value)}
              required
            >
              {optionArr && optionArr.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <br />

        <br />
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="duration" mb="0">
            <Heading fontSize="xl" fontFamily="body">
              Duration
            </Heading>
          </FormLabel>
          <Input
            type="number"
            placeholder="0"
            value={duration}
            onChange={(e) => setDuration(parseInt(e.target.value))}
          />
        </FormControl>
            <br />
        <Box bg="blue.100" p={4}>
          <Stack
            direction={{ base: "row", md: "column" }}
            display="flex"
            spacing={8}
          >
            <Flex flexWrap="wrap">
              <Box flexBasis={{ base: "100%", md: "50%" }}>
                <Box>
                  <Input
                    type="file"
                    accept="audio/*"
                    required
                    display="none"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button leftIcon={<AddIcon />} onClick={handleFileSelect}>
                    Upload File
                  </Button>
                  {selectedFile && (
                    <Text
                      display="flex"
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      Selected File: {selectedFile}
                    </Text>
                  )}
                </Box>
              </Box>
            </Flex>
            {selectedFile && (
              <Box flexBasis={{ base: "50%", md: "50%" }}>
                <audio controls style={{ width: "50%" }}>
                  <source src={playFile} type="audio/mp3" />
                </audio>
              </Box>
            )}
          </Stack>
        </Box>
        <br />
        <FormHelperText>Inner Workout</FormHelperText>
      </FormControl>
      <Button onClick={handleSubmit} colorScheme="blue">
        Save
      </Button>
      <br />
    </>)}
   </> 
  );
}
export default AddAudioAffirmation;
