import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { deleteMethod } from "API/APIResponse";
import { AxiosError } from "axios";
import { deleteUser } from "API/apiList";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";

function DeleteUser(props: { updatePage: any, closeModal: any, userId: string, isModal: boolean }) {
  const toast = useToast();
  const [isModalOpen, setModalOpen] = useState(props.isModal);

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      await deleteMethod(`${deleteUser}/${props.userId}`, {}, token);
      setModalOpen(false)
      props.updatePage();
      toast({
        title: "User deleted Successfully",
        description: "",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      const axiosError: AxiosError = error;
      toast({
        title: "User deleted failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => props.closeModal()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="red ">Do You want to delete this User</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => props.closeModal()}>
              NO
            </Button>
            <Button variant='ghost' onClick={() => handleDelete()} >Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteUser;