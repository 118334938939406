import React, { useState } from "react";
import Card from "components/card";
import ListGuidedJob from "./components/ListGuidedJob";
import AddGuidedJob from "./components/AddGuidedJob";
import { Button, Select ,Grid} from "@chakra-ui/react";



const Tables = () => {
  const [addGuidedJob, setAddGuidedJob] = useState(false);
  const handleChange = () => {
    setAddGuidedJob(!addGuidedJob);
  };
  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
      <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
           {addGuidedJob ? "Add Guided Journal" : "Guided Journals List" } 
          </div>

          {addGuidedJob === true ? <></> : 
          <Button colorScheme="blue" onClick={handleChange}>
            Add Guided Journal
          </Button>
         }
        </header>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addGuidedJob ? (<AddGuidedJob  handleChange={handleChange} />) : (  <ListGuidedJob  />) }
        </div>
      </Card>
    </div>
  );
};

export default Tables;
