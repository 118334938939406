import React, { useContext, useEffect, useState } from "react";
import EditJourneyDay from "./EditJourneyDay";
import tableDataDevelopment from "../variables/tableDataDevelopment";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";

import { ViewIcon, EditIcon } from "@chakra-ui/icons";
// import Pagination from 'react-bootstrap/Pagination';
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { getMethod } from "API/APIResponse";
import { fetchJourneyDay } from "API/apiList";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import { Context } from "context/context";
const apiUrl = process.env.REACT_APP_API_URL;
type RowObj = {
  name: [string, boolean];
  progress: string;
  quantity: number;
  date: string;
};

interface TableData {
  id: number;
  day: number;
  title: string;
  isPrompt: boolean;
  isListening: boolean;
  isBreathWork: boolean;
  audio: string;
  promptText: string;
  // Add more data properties as needed
}

function ListJourney(props: { tableData: any }) {
  // const { tableData } = props;
  const toast = useToast();
  const { search, initialRender } = useContext(Context);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [totalPages, setTotalPage] = useState(0);
  const [indexOfLastItem, setindexOfLastItem] = useState(0);
  const [editJourneyDay, setEditJourneyDay] = useState(false);
  const [itemData, setItemData] = useState({});

  const handleEditChange = (item: any) => {
    setEditJourneyDay(!editJourneyDay);
    setItemData(item);
    fetchData(currentPage);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };
  const Question = (id: any) => {
    navigate(`journeyQuestion/${id}`);
  };
  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      let url = `${fetchJourneyDay}?journeyId=${
        window.location.href.split("journey/")[1]
      }`;
      url += search ? `&search=${search}` : ``;
      const result: any = await getMethod(url, {}, token);
      if (result) {
        const { data } = result;
        if (data) {
          const { results, totalPages, page, limit, totalResults } = data;

          setTotalPage(totalPages);
          setCurrentPage(page);
          setindexOfLastItem(totalResults);
          setItemPerPage(limit);
          setCurrentItems(results);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Journey List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (initialRender) {
      fetchData();
    } else {
      const timeout = setTimeout(() => {
        fetchData();
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [search]);

  return (
    <>
      {editJourneyDay ? (
        <EditJourneyDay
          tableData={tableDataDevelopment}
          journeyId={window.location.href.split("journey/")[1]}
          handleChange={handleEditChange}
          data={itemData}
        />
      ) : (
        <div>
          <Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Day</Th>
                  <Th>Title</Th>
                  <Th>isBreathWork</Th>
                  <Th>isPrompt</Th>
                  <Th>isListening</Th>
                  <Th>Prompt-Text</Th>
                  <Th>Audio</Th>
                  <Th>Questions</Th>
                  {/* Add more table headers for additional data */}
                </Tr>
              </Thead>
              <Tbody>
                {data.map((item) => (
                  <Tr key={item.id}>
                    <Td>{item.id}</Td>
                    <Td>{item.day}</Td>
                    <Td>{item.title}</Td>
                    <Td>{item.isBreathWork ? "true" : "false"}</Td>
                    <Td>{item.isPrompt ? "true" : "false"}</Td>
                    <Td>{item.isListening ? "true" : "false"}</Td>
                    <Td>{item.promptText}</Td>
                    <Td>
                      <audio controls>
                        <source src={apiUrl + item.audio} type="audio/mp3" />
                      </audio>
                    </Td>
                    <Td className="buttonRow">
                      <Button
                        colorScheme="blue"
                        onClick={(e) => Question(item.id)}
                      >
                        <ViewIcon />
                      </Button>
                      <Button
                        className="editButton"
                        onClick={(e) => handleEditChange(item)}
                      >
                        <EditIcon />
                      </Button>
                    </Td>

                    {/* Add more table data for additional properties */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Box display="flex" justifyContent="center" mt={4}>
              {Array.from({ length: totalPages }).map((_, index) => (
                <Box
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  cursor="pointer"
                  fontWeight={index + 1 === currentPage ? "bold" : "normal"}
                  mx={2}
                >
                  <Button color="blue">{index + 1}</Button>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
      )}
    </>
  );
}

export default ListJourney;
