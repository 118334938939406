import React, { useState } from "react";
import {
  FormLabel,
  Input,
  Button,
  FormControl,
  Stack,
  Avatar,
  Heading,
  useToast,
  Select,
  Flex,
  Box,
  Spinner,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";
import logo from "assets/img/logo.png";
import { AxiosError } from "axios";
import { createExplore } from "API/apiList";
import { postMethod } from "API/APIResponse";
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "@chakra-ui/icons";
interface JournalQuestion {
  question: string;
  hint: string;
  suggestions: string;
}

function AddGuidedJob(props: { handleChange: any }) {
  const toast = useToast();

  const quizArray = [
    "Bliss",
    "Wisdom",
    "Mental & emotional",
    "Energetic",
    "Physical",
  ];

  const stateArrayValues = ["Grow", "Focus", "Relax", "Sleep"];

  const [name, setName] = useState<string>("");
  const [dimension, setDimension] = useState<string>(quizArray[0]);
  const [desiredEndState, setDesiredEndState] = useState<string>(
    stateArrayValues[0]
  );
  const [promptText, setPromptText] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [optionArr, setOptionArr] = useState<string[]>(stateArrayValues);
  const [loading, setLoading] = useState(false);
  const [journalQuestion, setJournalQuestion] = useState<JournalQuestion[]>([
    { question: null, hint: null, suggestions: null },
  ]);
  const [showButton, setShowButton] = useState([0]);
  const [buttonIndex, setButtonIndex] = useState(0);
  const [isPrompt, setIsPrompt] = useState<boolean>(true);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false);
  const [isPromptInvalid, setIsPromptInvalid] = useState<boolean>(false);

  // const [listing, setLising] = useState(false);
  const navigate = useNavigate();

  const handleAddJournalQuestion = () => {
    let m = journalQuestion.length - 1;
    setButtonIndex(m + 1);
    setShowButton([buttonIndex + 1]);

    if (journalQuestion.length && journalQuestion[journalQuestion.length - 1]) {
      setJournalQuestion([
        ...journalQuestion,
        { question: null, hint: null, suggestions: null },
      ]);
    } else {
      setJournalQuestion([
        ...journalQuestion,
        { question: null, hint: null, suggestions: null },
      ]);
    }
  };
  const handleGuidedJournal = (
    index: number,
    key: "question" | "hint" | "suggestions",
    value: string
  ) => {
    const updatedInputs = [...journalQuestion];
    if (value == "") {
      updatedInputs[index][key] = null;
    } else {
      updatedInputs[index][key] = value.trim() || null;
    }
    setJournalQuestion(updatedInputs);
  };

  const handleRemoveJournalQuestion = (index: number) => {
    const updatedInputs = [...journalQuestion];
    updatedInputs.splice(index, 1);
    setJournalQuestion(updatedInputs);
  };

  const handleDemention = (e: any) => {
    const dimentionValue = e.target.value;
    setDimension(dimentionValue);
    setOptionArr(stateArrayValues);
  };

  const handleSubmit = async () => {
    try {
      // setLoading(true)
      const token = localStorage.getItem("token");
      // console.log(name==="")
      if (name === "") {
        setIsNameInvalid(true);
        return;
      }
      if (promptText === "") {
        setIsPromptInvalid(true);
        return;
      }

      // const formData = new FormData();
      // formData.append("exploreType", 'Guided Journal');
      // formData.append("duration", duration.toString());
      // formData.append("dimension", dimension);
      // formData.append("name", name);
      // formData.append("promptText", promptText);
      // formData.append("desiredEndState", desiredEndState);
      if (!isNameInvalid && !isPromptInvalid) {
        setLoading(true);
        
        const quesArray: any[] = [];
        for (const question of journalQuestion) {
          const { question: q, hint, suggestions } = question;
          const trimmedQuestion = q ? q.trim() || null : null;
          const trimmedHint = hint ? hint.trim() || null : null;
          const trimmedSuggestions = suggestions ? suggestions.trim() || null : null;      
          quesArray.push({
            question: trimmedQuestion,
            hint: trimmedHint,
            suggestions: trimmedSuggestions
          });
        }

        const data = {
          exploreType: 'Guided Journal',
          duration, dimension, 
          name: name.trim() ? name.trim() : null, 
          promptText: promptText.trim() ? promptText.trim() : null, 
          desiredEndState, 
          journalQuestion: quesArray, 
          isPrompt: true,
        }
        const result = await postMethod(createExplore, data, token);
        if (result) {
          setLoading(false);
          // setLising(true)
          toast({
            title: "GuidedJob Created Successfully",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          props.handleChange();
        }
      }
    } catch (error: any) {
      setLoading(false);
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "GuidedJob Create Failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {loading && (
        <Flex
          align="center"
          justify="center"
          height="100vh"
          direction="column"
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      )}
      {/* {listing && !loading && (<ListGuidedJob />)} */}
      {!loading && (
        <>
          <FormControl
            p={8}
            borderColor="gray"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
          >
            <Stack mt={8} direction="row" spacing={4}>
              <Avatar size="xl" src={logo} mb={4} pos="relative" />
            </Stack>
            <br />
            <Box display="flex" alignItems="center" gap={4}>
              <FormControl
                display="flex"
                alignItems="center"
                isInvalid={isNameInvalid}
              >
                <FormLabel htmlFor="Name" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    Name
                  </Heading>
                </FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setIsNameInvalid(false);
                  }}
                  isRequired
                />
                {isNameInvalid ? (
                  <FormErrorMessage className="ml-4">
                    Name is required
                  </FormErrorMessage>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="dimension" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    Dimension
                  </Heading>
                </FormLabel>
                <Select
                  id="dimension"
                  value={dimension}
                  onChange={(e) => handleDemention(e)}
                  required
                >
                  {quizArray &&
                    quizArray.map((e) => (
                      <option key={e} value={e}>
                        {e}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <br />
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="desiredEndState" mb="0">
                  <Heading fontSize="xl" fontFamily="body">
                    Desired End State
                  </Heading>
                </FormLabel>
                <Select
                  id="desiredEndState"
                  value={desiredEndState}
                  onChange={(e) => {
                    setDesiredEndState(e.target.value);
                  }}
                  required
                >
                  {optionArr &&
                    optionArr.map((e) => (
                      <option key={e} value={e}>
                        {e}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <br />

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="duration" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  Duration
                </Heading>
              </FormLabel>
              <Input
                type="number"
                placeholder="0"
                value={duration}
                onChange={(e) => setDuration(parseInt(e.target.value))}
              />
            </FormControl>
            <br />
            <FormControl
              display="flex"
              alignItems="center"
              isInvalid={isPromptInvalid}
            >
              <FormLabel htmlFor="duration" mb="0">
                <Heading fontSize="xl" fontFamily="body">
                  Prompt Text
                </Heading>
              </FormLabel>
              <Textarea
                placeholder="promptText"
                value={promptText}
                onChange={(e) => {
                  setPromptText(e.target.value);
                  setIsPromptInvalid(false);
                }}
              />
              {isPromptInvalid ? (
                <FormErrorMessage className="ml-4">
                  Prompt Text is required
                </FormErrorMessage>
              ) : (
                ""
              )}
            </FormControl>
            <br />
            {journalQuestion.map((input, index) => (
              <Box key={index} bg="gray.100" p={4}>
                <Stack direction={"row"} spacing={4}>
                  <Flex alignItems="center" flex="1">
                    <FormControl>
                      <FormLabel>Question</FormLabel>
                      <Input
                        type="text"
                        value={input.question}
                        onChange={(e) =>
                          handleGuidedJournal(index, "question", e.target.value)
                        }
                      />
                    </FormControl>
                  </Flex>
                  <Flex alignItems="bottom" flex="1">
                    <FormControl>
                      <FormLabel>Hint</FormLabel>
                      <Input
                        type="text"
                        value={input.hint}
                        onChange={(e) =>
                          handleGuidedJournal(index, "hint", e.target.value)
                        }
                      />
                    </FormControl>
                  </Flex>
                  <Flex alignItems="center" flex="1">
                    <FormControl>
                      <FormLabel>Suggestion</FormLabel>
                      <Input
                        type="text"
                        value={input.suggestions}
                        onChange={(e) =>
                          handleGuidedJournal(
                            index,
                            "suggestions",
                            e.target.value.trim()
                          )
                        }
                      />
                    </FormControl>
                  </Flex>
                  <Flex alignItems="end">
                    <Button
                      colorScheme="red"
                      onClick={() => handleRemoveJournalQuestion(index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Flex>
                </Stack>
                <br />
                <Stack>
                  {showButton.includes(buttonIndex) &&
                    index === journalQuestion.length - 1 && (
                      <Button
                        colorScheme="teal"
                        onClick={handleAddJournalQuestion}
                      >
                        Add New Question
                      </Button>
                    )}
                </Stack>
              </Box>
            ))}

            <Button
              onClick={handleSubmit}
              colorScheme="blue"
              style={{ width: "100%" }}
            >
              Save
            </Button>
            <br />
          </FormControl>
        </>
      )}
    </>
  );
}
export default AddGuidedJob;
