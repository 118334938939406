import Card from "components/card";
import tableDataDevelopment from "./variables/tableDataDevelopment";
import ListJourneyDay from "./components/ListJourneyDay";
import AddJourneyDay from "./components/AddQuestion";
import { useEffect, useState } from "react";
import { getMethod } from "API/APIResponse";
import { fetchJourney } from "API/apiList";
import { AxiosError } from "axios";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Tables = () => {
  const [addJourney, setAddJourney] = useState(false);
  const [ journeyName, setJourneyName ] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const handleChange = () => {
    setAddJourney(!addJourney);
  };

  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem('token');
      const result: any = await getMethod(fetchJourney + `/${window.location.href.split('journey/')[1]}`, {}, token);
      if (result) {
        const { data } = result;
        if (data) {
          const {
            journeyName
            } = data;
            setJourneyName(journeyName);

        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Journey List Fetch failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {journeyName}
          </div>

          {/* <CardMenu /> */}
          <Button onClick={handleChange}>Add Day</Button>
          {/* <button type="button" onClick={handleChange} className="linear mt-2  rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >Add Day</button> */}
        </header>

        {addJourney === true ? <div className="grid h-full grid-cols-1 gap-5">
          <AddJourneyDay tableData={tableDataDevelopment} handleChange={handleChange} journeyId={window.location.href.split('journey/')[1]}/>
        </div> :
          <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
            <ListJourneyDay tableData={tableDataDevelopment} />
          </div>
        }
      </Card>
    </div>
  );
};

export default Tables;
