import React, { useEffect, useState } from "react";
import Card from "components/card";
import ListAffirmation from "./components/ListAffirmation";
import { Grid } from '@chakra-ui/react'
import AddAffirmation from "./components/AddAffirmation";
import { Button } from "@chakra-ui/react";

const Tables = () => {
  const [addAffirmation, setAddAffirmation] = useState(false);

  const handleChange = () => {
    setAddAffirmation(!addAffirmation);
  };
  useEffect(() => {
  }, []);


  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto px-6">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
          </div>
          <Grid gap={2}>
            {addAffirmation === true ? <></> : <Button colorScheme="blue" onClick={handleChange}>
              Add Affirmation
            </Button>}
          </Grid>
        </header>

        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {addAffirmation ? (
            <AddAffirmation handleChange={handleChange} />
          ) : (
            <ListAffirmation />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Tables;
