import React, { useState } from 'react';
import logo from 'assets/img/logo.png';
import {
  FormControl,
  Stack,
  FormHelperText,
  Avatar,
  Heading,
  useToast,
  Button,
} from '@chakra-ui/react';
import { getMethod, patchMethod } from 'API/APIResponse';
import { fetchJourneyAll, updateQuizJourney } from 'API/apiList';
import { AxiosError } from 'axios';

import { useNavigate } from 'react-router-dom';
import SelectField from './SelectField';
import { Select, useChakraSelectProps } from 'chakra-react-select';
import { Energetic, Physical, blissArray, mentalAndEnergeticArray, noQquizArray2, noQuizArray, noQuizArray3, noQuizArray4, noQuizArray5, noQuizArray6, wisdomArray, yesArray } from './DummyData';


const EditQuizJourney: React.FC<{ tableData: any; handleChange: any; data: any; }> = (props) => {
  const { data, handleChange } = props;
  const toast = useToast();

  const [id, setId] = useState<string>('');
  const [optionOne, setOptionOne] = useState<any>([]);
  const [optionTwo, setOptionTwo] = useState<any>([]);
  const [optionThree, setOptionThree] = useState<any>([]);
  const [optionOneOption, setOptionOneOption] = useState<any>([
    { label: 'Yes', value: 'Yes' }, { label: 'No', value: 'Not yet' }
  ]);
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [journeyOptions, setJourneyOptions] = useState<any>([]);

  const selectProps = useChakraSelectProps({
    // isMulti: true,
    isSearchable: true,
    value: selectedOptions,
    options: journeyOptions,
    onChange: setSelectedOptions,
  });
  const fetchJourneys = async () => {
    try {
      const token = localStorage.getItem('token');
      const result: any = await getMethod(fetchJourneyAll, {}, token);
      if (result) {
        const { data } = result;
        if (data.length) {
          const options = [];
          for (let journey of data) {
            options.push({ label: journey.journeyName, value: journey.id });
          }
          console.log(options);
          setJourneyOptions(options);
        }
      }
    } catch (error) {

    }
  };
  useState(() => {
    fetchJourneys();
    if (data) {
      console.log(data);
      setId(data.id);
      setSelectedOptions(data.journey);
      setOptionOne({label: data.optionOne, key: data.optionOne});
      setOptionTwo({label: data.optionTwo, key: data.optionTwo});
      setOptionThree({label: data.optionThree, key: data.optionThree});
    }
  })

  const handleSave = async () => {
    try {
      const result = await patchMethod(updateQuizJourney + `/${id}`, {
        journey: selectedOptions,
        optionOne: optionOne.value, optionTwo: optionTwo.value, optionThree: optionThree.value
      }, localStorage.getItem('token'));

      if (result) {
        setId('');
        setSelectedOptions([]);
        setOptionOne({});
        setOptionTwo({});
        setOptionThree({});
        toast({
          title: 'Quiz Journey Updated Successfully',
          description: '',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        handleChange(result);
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Quiz Journey Create Failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack spacing={4} m={4} p={4}>
        <FormControl p={8} borderColor="blue" borderWidth={1} borderRadius={8} boxShadow="lg">
          <Stack mt={8} direction="row" spacing={4}>
            <Avatar size="xl" src={logo} mb={4} pos="relative" />
            <Heading fontSize="2xl" fontFamily="body">
              Quiz Journey
            </Heading>
          </Stack>

          <SelectField option={optionOne} optionOption={optionOneOption} setOption={setOptionOne} />
          <br />
          {/* {optionOne} */}
          {
            (optionOne && optionOne.label === 'Yes') && <>
              <SelectField option={optionTwo} optionOption={yesArray} setOption={setOptionTwo} />
            </>}
          {(optionOne && optionOne.label === 'No') && <>
            <SelectField option={optionTwo} optionOption={noQuizArray} setOption={setOptionTwo} />
          </>
          }
          <br />
          {
            (optionTwo && optionTwo.label === 'Physical' && optionOne.label === 'Yes') &&
            <SelectField option={optionThree} optionOption={Physical} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'Energetic' && optionOne.label === 'Yes') &&
            <SelectField option={optionThree} optionOption={Energetic} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'Mental & emotional' && optionOne.label === 'Yes') &&
            <SelectField option={optionThree} optionOption={mentalAndEnergeticArray} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'Wisdom' && optionOne.label === 'Yes') &&
            <SelectField option={optionThree} optionOption={wisdomArray} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'Bliss' && optionOne.label === 'Yes') &&
            <SelectField option={optionThree} optionOption={blissArray} setOption={setOptionThree} />
          }
          {/* For No */}
          {
            (optionTwo && optionTwo.label === 'I feel disconnected in my life.' && optionOne.label === 'No') &&
            <SelectField option={optionThree} optionOption={noQuizArray6} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'I’m not supporting my highest good through my actions.' && optionOne.label === 'No') &&
            <SelectField option={optionThree} optionOption={noQuizArray5} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'My brain feels like it’s working against me, not with me.' && optionOne.label === 'No') &&
            <SelectField option={optionThree} optionOption={noQuizArray4} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'My energy is all over the place.' && optionOne.label === 'No') &&
            <SelectField option={optionThree} optionOption={noQuizArray3} setOption={setOptionThree} />
          }
          {
            (optionTwo && optionTwo.label === 'My relationship with my body isn’t what it could be.' && optionOne.label === 'No') &&
            <SelectField option={optionThree} optionOption={noQquizArray2} setOption={setOptionThree} />
          }
          <br />
          {optionThree && <Select
            {...selectProps}
          />}

          <br />
          <Button colorScheme="blue" onClick={handleSave} >
            Save Quiz Journey
          </Button>
          <FormHelperText>Inner Workout</FormHelperText>
        </FormControl>
      </Stack>
    </>
  );

};

export default EditQuizJourney;
