import React, { useState } from 'react';
import { FormLabel, Input, Button, FormControl, Stack, FormHelperText, Avatar, Heading, useToast } from '@chakra-ui/react';
import logo from 'assets/img/logo.png';
import { patchMethod } from 'API/APIResponse';
import { updateJourneyDayQuestion } from 'API/apiList';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

function EditQuestion(props: { handleChange: any; data:any }) {
  const toast = useToast();
  const [inputs, setInputs] = useState<string>(props.data?.question); // Initialize with one input field
  const [hint, setHints] = useState<string>(props.data?.hint); // Initialize with two hints
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      const data = {
        question: inputs,
        journeyDayId: window.location.href.split('journeyQuestion/')[1],
        hint
      };
      const token = localStorage.getItem('token');
      if (data) {
        const result = await patchMethod(updateJourneyDayQuestion+`/${props.data?.id}`, data, token);
        if (result) {
          toast({
            title: 'Journey Question Updated Successfully',
            description: '',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          props.handleChange(result);
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate('/auth/sign-in');
      }
      toast({
        title: 'Journey Question Updated Failed',
        description: axiosError?.response?.statusText,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <FormControl p={8} borderColor="blue" borderWidth={1} borderRadius={8} boxShadow="lg">
        <Stack mt={8} direction="row" spacing={4}>
          <Avatar size="xl" src={logo} mb={4} pos="relative" />
        </Stack>
        <FormControl display="flex" alignItems="center" >
              <FormLabel htmlFor={`question`} mb="0">
                <Heading fontSize="m" fontFamily="body">
                  Question 
                </Heading>
              </FormLabel>
              <Input type="text" value={inputs} onChange={(e) => setInputs(e.target.value)} />
              <FormLabel htmlFor={`question`} mb="0">
                <Heading fontSize="m" fontFamily="body" className='ml-2'>
                  Hint 
                </Heading>
              </FormLabel>
              <Input type="text" value={hint} onChange={(e) => setHints(e.target.value)} />
              <Button className='ml-2 w-[120px]' onClick={() => {
                setInputs('');
                setHints('');
              }
                } >Remove</Button>
            </FormControl>
      
        <FormHelperText>Inner Workout</FormHelperText>
      </FormControl>
      <Button onClick={handleSubmit} colorScheme="blue">
        Save
      </Button>
    </>
  );
}

export default EditQuestion;
