import React, { useContext, useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import EditJourney from "./EditJourney";
import { useTable, usePagination } from "react-table";
// import Table from 'react-bootstrap/Table';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
} from "@chakra-ui/react";

// import {
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Flex,
//   IconButton,
//   Text,
//   Tooltip,
//   Select,
//   NumberInput,
//   NumberInputField,
//   NumberInputStepper,
//   NumberIncrementStepper,
//   NumberDecrementStepper
// } from "@chakra-ui/react";
import { ViewIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
// import Pagination from 'react-bootstrap/Pagination';

import { createColumnHelper } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { deleteMethod, getMethod } from "API/APIResponse";
import { deleteJourney, fetchJourney } from "API/apiList";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import tableDataDevelopment from "../variables/tableDataDevelopment";
import { Context } from "context/context";

type RowObj = {
  name: [string, boolean];
  progress: string;
  quantity: number;
  date: string;
};

interface TableData {
  id: string;
  journeyName: string;
  email: string;
  description: string;
  // Add more data properties as needed
}

function ListJourney(props: { tableData: any }) {
  // const { tableData } = props;
  const {
    search,
    initialRender
    // data,
    // setCurrentItems,
    // currentPage,
    // setCurrentPage,
    // totalPages,
    // setTotalPage,
    // itemsPerPage,
    // setItemPerPage,
    // indexOfLastItem,
    // setindexOfLastItem,
  } = useContext(Context);
  const navigate = useNavigate();
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [totalPages, setTotalPage] = useState(0);
  const [indexOfLastItem, setindexOfLastItem] = useState(0);
  const [data, setCurrentItems] = useState<TableData[]>([]);
  const [editJourney, setEditJourney] = useState(false);
  const [itemData, setItemData] = useState({});
  const handleChange = (item: any) => {
    setEditJourney(!editJourney);
    setItemData(item);
    fetchData(currentPage);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchData(newPage);
  };

  const viewJourney = (id: any) => {
    navigate(`/journey/${id}`);
  };
  const deleteJourneyMethod = async (id: any) => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const result: any = await deleteMethod(
          deleteJourney + `/${id}`,
          {},
          token
        );
        if (result) {
          await fetchData(currentPage);
          toast({
            title: "Journey Delete",
            description: `Journey id ${id} deleted successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Journey Delete failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchData = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      let url = `${fetchJourney}?page=${page}&limit=10`;
      url += search ? `&search=${search}` : ``;
      if (token) {
        const result: any = await getMethod(
          url,
          {},
          token
        );
        if (result) {
          const { data } = result;
          if (data) {
            const { results, totalPages, page, limit, totalResults } = data;
            setTotalPage(totalPages);
            setCurrentPage(page);
            setindexOfLastItem(totalResults);
            setItemPerPage(limit);
            setCurrentItems(results);
          }
        }
      }
    } catch (error: any) {
      const axiosError: AxiosError = error;
      if (axiosError.response.status === 401) {
        localStorage.clear();
        navigate("/auth/sign-in");
      }
      toast({
        title: "Journey List Fetch failed",
        description: axiosError?.response?.statusText,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if(initialRender){
      fetchData();
    }else{
      const timeout = setTimeout(() => {
        fetchData();
      }, 500);
  
      return ()=>{
        clearTimeout(timeout)
      }
    }
  }, [props,search]);

  return (
    <div>
      {editJourney ? (
        <EditJourney
          tableData={tableDataDevelopment}
          handleChange={handleChange}
          data={itemData}
        />
      ) : (
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Journey Name</Th>
                <Th>Description</Th>

                <Th>Actions</Th>
                {/* Add more table headers for additional data */}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.id}</Td>
                  <Td>{item.journeyName}</Td>
                  <Td>{item.description}</Td>
                  <Td>
                    {" "}
                    <div className="buttonRow">
                      <Button
                        colorScheme="blue"
                        onClick={() => viewJourney(item.id)}
                      >
                        <ViewIcon />
                      </Button>
                      <Button
                        className="editButton"
                        onClick={() => handleChange(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => deleteJourneyMethod(item.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box display="flex" justifyContent="center" mt={4} mb={4}>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Box
                key={index}
                onClick={() => handlePageChange(index + 1)}
                cursor="pointer"
                fontWeight={index + 1 === currentPage ? "bold" : "normal"}
                mx={2}
              >
                <Button color="blue">{index + 1}</Button>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
}
export default ListJourney;
