import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import "./pagination.css";
import { useNavigate } from "react-router-dom";
import { Context } from "context/context";
import EditNotifications from "./EditNotification";
import { getMethod } from "API/APIResponse";
import { getNotification } from "API/apiList";
import Loader from "./Loader/Loader";

// getNotification
function ListNotification(props: { selected: string;  }) {
  const {
    search,
    initialRender
  } = useContext(Context);
  const [data, setCurrentItems] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const [listing,setListing]=useState(true)
  const [itemData, setItemData] = useState([]);

  const handleChange = (item: any) => {
    setEdit(!edit);
    setItemData(item);
  };
  
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await getMethod(getNotification, {}, token);
      if (res) {
        setCurrentItems([res]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (initialRender) {
      fetchData();
    }
  }, [edit]);
  return (
    <div>
      {edit && (
        <EditNotifications
          handleChange={handleChange}
          data={itemData}
          setEdit={setEdit}
          setCurrentItems={setCurrentItems}
        />
      )
     } 
     { !edit && (
        <Box>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Notification Type</Th>
                <Th>Notification Message</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.length >0 && data?.map((item: any, index: number) => (
                Object.entries(item).map(([key, value], subIndex) => (
                  <Tr key={`${index}-${subIndex}`}>
                    <Td>{index + subIndex + 1}</Td>
                    <Td>{key}</Td>
                    <Td>{String(value)}</Td>
                    <Td>
                      <div className="buttonRow flex justify-center items-center">
                        <Button
                          className="editButton"
                          onClick={() => handleChange([key, value])}
                        >
                          <EditIcon />
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </div>
  );
}
export default ListNotification;
